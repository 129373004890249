$discussion-header-height: 40px;
$discussion-footer-height: 72px;

#discussion-group-label-text-style {
  text-transform: uppercase;
  font-size: 75%;
  font-weight: bold;

  text-align: center;
}

.past-revisions {
  .selected {
    span {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.workflow-tool {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.overview {
    width: 100%;
    overflow-y: auto;

    .overview-divider,
    .overview-variable-divider {
      margin-left: $base-padding * 2;
    }

    .overview-variable {
      &:not(:hover) {
        .overview-variable-start-edit {
          button {
            display: none;
            opacity: 0;
          }
        }
      }

      .overview-variable-name {
        padding-right: $base-padding;
        max-width: 33%;
        min-width: 33%;

        word-wrap: break-word;
        text-overflow: ellipsis;

        span {
          font-weight: bold;
        }
      }

      &.edit {
        margin: 0;
        padding: 0;

        form {
          display: flex;
          align-items: center;
          width: 100%;

          .MuiFormControl-root {
            margin: 0;
            padding-right: $base-padding;
          }

          button {
            min-height: 30px;
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }

  &.discussion {
    position: relative;

    .discussion-header {
      position: absolute;

      display: flex;

      height: $discussion-header-height;
      width: 100%;

      align-items: center;
      justify-content: flex-end;

      background-color: rgba($background-color, 0.75);

      .discussion-topic {
        display: none;
        @extend #discussion-group-label-text-style;
      }

      .discussion-notification-state {
        position: absolute;
        right: #{$base-padding * 2};
      }
    }

    .discussion-body {
      flex-shrink: 1;
      flex-grow: 1;
      overflow-y: auto;
      height: calc(100% - #{$discussion-footer-height});

      padding-top: 1px;
    }

    .discussion-footer {
      position: relative;
      flex-shrink: 0;

      .discussion-disabled {
        width: 100%;

        color: rgba(0, 0, 0, 1);
        text-align: center;
      }
    }

    .comment-groups {
      display: flex;
      width: 100%;
      min-height: 100%;

      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;

      padding: 0 #{$base-padding * 2};

      scroll-margin-top: 40px;
      scroll-padding-top: 40px;

      &.empty-groups {
        height: 100%;
      }

      .comment-group {
        display: flex;
        flex-direction: column;
        margin-bottom: $card-margin;

        &:first-child {
          padding-top: 40px;

          .divider {
            display: none;
          }

          .comment-group-header {
            height: 0 !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          .comment-group-header {
            position: inherit;
          }
        }

        .comment-group-label {
          z-index: 1;
        }

        .comment-group-header {
          height: $discussion-header-height;
          position: relative;

          display: flex;
          justify-content: center;

          transition: position 2s;

          .comment-group-label {
            position: absolute;
            top: 0;

            @extend #discussion-group-label-text-style;

            background-color: $background-color;
            max-width: 50%;

            margin-top: 8px;

            padding: 4px 8px;
            border-radius: 40px;

            z-index: 2;
          }
        }

        .comment {
          display: flex;
          flex-direction: column;

          flex-grow: 0;

          max-width: 75%;
          width: fit-content;

          margin-top: 4px;

          &:first-child {
            padding-top: 0;
          }

          &.own {
            align-self: flex-end;

            .comment-content {
              background: $main-ui-color;
              align-self: flex-end;
            }
          }

          .comment-info {
            display: flex;

            text-transform: uppercase;
            font-size: 75%;
            font-weight: bold;

            justify-content: space-between;

            padding: 5px 8px 0 8px;

            .user {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow-x: hidden;
            }

            .date {
              flex-shrink: 0;
            }
          }

          .spacing {
            min-width: 1em;
          }

          .comment-content {
            width: fit-content;

            overflow-wrap: break-word;
            word-break: break-all;

            border: $border-width $border-type lightgray;
            border-radius: 16px;

            padding: 5px 8px;
          }
        }
      }
    }

    .comment-box {
      width: 100%;
      position: relative;
      padding: #{$base-padding * 2};

      .input-container {
        position: relative;
        display: flex;

        .MuiTextField-root {
          margin: 0;
        }

        .input-send {
          display: flex;
          align-self: center;
          margin-left: $button-margin;
          align-self: flex-start;
          margin-top: 5px;
        }
      }
    }
  }

  &.change {
    position: relative;
    height: 100%;
    width: 100%;

    .no-change {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      padding: $base-padding * 2;
    }

    .change-form {
      height: 100%;
      flex-direction: column;
      overflow: hidden;

      .change-content {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        height: calc(100% - 50px);
        overflow-y: auto;

        &.inside-tab {
          height: calc(100vh - 340px);
        }

        .change-form-information {
          display: flex;
          flex-direction: column;
          padding: 2px 10px 2px 10px !important;

          > * {
            margin: $base-padding;
          }
        }

        .change-form-notifications {
          padding: 2px 10px 2px 10px !important;

          .settings-button {
            padding: 0;

            .settings-button-container {
              width: 100%;
              height: 100%;
              min-height: 60px;

              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              .settings-button-text {
                display: flex;
                flex-grow: 1;
                align-items: center;
              }

              .settings-button-icon {
                flex-grow: 0;
                margin: calc($base-padding / 2) 0;

                svg {
                  font-size: 175%;
                }
              }
            }
          }
        }

        .change-notification-info {
          width: 100%;
          padding: 10px 20px 0px;
        }
      }

      .change-form-actions {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        padding: $base-padding * 2;

        border-top: $default-border;
        background-color: $background-color;

        button {
          margin-left: $base-padding * 2;
          align-self: center;
        }

        .change-actions-right {
          align-self: center;
        }
      }
    }

    .change-form-actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
      padding: $base-padding * 2;

      &.multi {
        justify-content: space-between;
        .change-actions-right {
          align-self: center;
        }
        button {
          align-self: center;
        }
      }
    }

    .role-task-mapping {
      grid-area: notification;
      table-layout: auto;

      tr {
        > *:first-child {
          min-width: 100px;
        }

        > *:not(:first-child) {
          min-width: 100px;
          max-width: 100px;
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      th {
        position: relative;
        padding: calc($base-padding / 2) calc($base-padding * 2);
        border-radius: 0;
        text-align: center;
        text-transform: initial;

        .overlay {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          padding-bottom: calc($base-padding / 2);

          justify-content: center;
          align-items: flex-end;

          background-color: rgba($background-color, 0.75);
          opacity: 0;
          transition: opacity 0.15s ease-in-out;
        }

        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      }

      td {
        text-align: center;
        padding: calc($base-padding / 2) calc($base-padding * 2);

        &:first-child {
          text-align: left;
        }
      }
    }

    button.add-change {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
  }

  &.attachment {
    .yonder-button--fab {
      position: absolute;
      right: #{$container-padding * 2};
      bottom: #{$container-padding * 2};
    }
  }
}

.attachment-upload-dialog {
  &__input {
    &-container.MuiGrid-item {
      padding-left: 0 !important;
      padding-right: 0 !important;
      flex-grow: 1;
    }

    .MuiInputBase-input {
      cursor: pointer;
      color: transparent;
      text-shadow: 0 0 0 $text-color;

      &:focus {
        outline: none;
      }
    }
  }

  .spinner {
    margin: 0;
  }
}
