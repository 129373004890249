.sidebar {
  flex: 0 0 332px;
  top: $navbar-height;
  left: 0;
  bottom: 0;
  background-color: $background-color;
  min-width: $sidebar-width;
  border-right: $default-border;
  padding: $container-padding 0;
  z-index: $zindex-sidebar;
  transition-property: all;
  transition-duration: 0.5s;
  overflow-y: auto;
  position: absolute;

  &.hidden {
    left: -#{$sidebar-width};
  }

  &__open-btn {
    position: absolute !important;
    top: $navbar-height + $container-padding;
    left: calc($base-padding / -2);
    z-index: $zindex-sidebar-open-btn;
    background-color: $background-color;
    border: $default-border;
    border-radius: 0 50% 50% 0;

    .MuiButtonBase-root {
      padding: $base-padding;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    justify-content: space-between;

    .top-links {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }

    .bottom-links {
      margin: 8px;
      padding: 0;
      flex-shrink: 0;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .vertical-tabs {
      flex-shrink: 0;

      &.inactive {
        div[class^='MuiTabs-flexContainer'] {
          button[aria-selected='true'] {
            color: $inactive-tab-icon-color;

            &::before {
              content: none;
            }
          }
        }
      }

      div[class^='MuiTabs-flexContainer'] {
        flex-direction: column;

        button {
          min-width: $sidebar-width;
        }

        button[aria-selected='true'] {
          position: relative;
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition-property: all;
          transition-duration: 300ms;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-delay: 0ms;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 2px;
            background-color: #3f79cf;
          }
        }
      }

      .vertical-tab {
        max-width: $sidebar-width;
        min-height: 64px;
      }
    }
  }

  & ~ .drawer {
    .container {
      padding-bottom: 0;
    }
  }
}
