.compare-revisions {
  --primary: #{$main-ui-color};
  height: calc(100% + #{$container-padding * 2});
  margin: -#{$container-padding};
  overflow-y: auto;
  justify-content: flex-start;
  min-height: 100%;
  width: calc(100% + #{$container-padding * 2});
  transition-property: all;
  transition-duration: 0.5s;
  padding: #{$base-padding * 2};

  @include media-breakpoint-up('sm') {
    margin-left: #{$sidebar-width - $container-padding};

    &.tools-open {
      margin-left: #{$sidebar-open-width - $container-padding};
    }
  }
}

.compare-revisions-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1em;

  .compare-revisions-wrapper-content {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    padding: 0.5em;
    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.compare-revisions-loading-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 8px;

  .compare-revisions-loading {
    align-self: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.compare-revisions-list {
  word-wrap: break-word;
  &__label {
    font-size: 14px;
    color: $text-color-light;
  }
  &__empty {
    padding: $button-margin;
  }
}
