.move-favorites-modal-content {
  overflow: overlay;
    .MuiDialog-paper {
        width: 26.625em;
      }
      .yonder-list {
        .favorite-group-list-item-divider {
          margin-left: 0;
        }
        .favorite-group-list-item+hr {
          display: none;
        }
      }
      .list-wrapper {
        padding: 1.625em 1.125em;
        .yonder-list {
          border: 1px solid #C1C1C1;
          min-height: 26.875em;
        }
      }
      .modal-input-title {
        align-items: flex-start;
      }
}

.move-favorites-modal-title {
    display: flex;
    flex-direction: column;
    h6 {
      font-size: 1.125rem;
      margin-top: 0;
      margin-bottom: 0.3em;
    }
    .subtitle {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

.revert-favorite-name-modal-content {
    font-size: 1rem;
    .favorite-default-name {
        color: $blue-color-all-themes;
    }
}