.sidebar-tool {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .highlight-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .list-item-primary {
            padding: 0.1em 0.25em;
        }
    }

    &.hidden {
        display: none;
    }

    .sidebar-tool-header {
        padding-bottom: $container-padding * 2;

        .title {
            padding: 0 #{$base-padding * 2};
        }

        .subtitle {
            padding: 0 #{$base-padding * 2};
            color: $inactive-tab-icon-color;
            font-size: 0.9em;

            width: 100%;
        }
    }

    .sidebar-tool-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .yonder-list-item.MuiListItem-root {
            &.item__change {
                .yonder-list-item-icon {
                    min-width: 0;
                }
            }
        }
        .filter-dashboard {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            &.opened {
                position: relative;
            }
        }
        .tabs {
            &.tabs-hidden {
                height: calc(100% - 340px);
                > .tabs-header {
                    display: none;
                }
            }
        }
        .transfer-highlights-button {
            display: flex;
            align-items: center;
            padding: 1rem;
            cursor: pointer;

            .transfer-highlights-warning {
                margin-right: 0.25rem;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }

    &__search {
        .sidebar-tool-body {
            overflow-y: hidden;
        }

        .search__container {
            height: $search-field-container-height;
            flex-wrap: nowrap;
            justify-content: center;
        }

        .search-field__container {
            display: flex;
            padding: #{$base-padding} #{$base-padding * 2};
        }

        .search-all {
            padding: 0 #{$base-padding * 2};
            margin-top: -#{$base-padding};
            cursor: pointer;
        }

        .search-results {
            height: calc(100% - #{$search-field-container-height});
            min-height: calc(100% - #{$search-field-container-height});
            width: 100%;
            margin: 0;

            .search-results-list {
                .yonder-infinity-list-container {
                    .yonder-infinity-list {
                        padding: 0;

                        .divider {
                            margin-left: $base-padding * 2;
                            width: calc(100% - #{$base-padding * 2});
                        }
                    }
                }

                .search-result {
                    padding: #{$base-padding} #{$base-padding * 2};
                    margin: 0;
                }
            }
        }
    }

    &.revision-tool {
        .sidebar-tool-body {
            .tabs {
                .tab-container {
                    .tasks-wrapper {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        height: 100%;
                        .tasks-tabs {
                            display: flex;
                            justify-content: space-evenly;
                            padding: 4px 5px;
                            margin: 0.2rem 0;
                            .tasks-button {
                                padding: 7px 20px;
                                background-color: $task-tab-button-background;

                                display: flex;
                                align-items: center;
                                border-radius: 7px;
                                width: 50%;
                                justify-content: space-evenly;

                                &.open {
                                    transform: translateX(0.5rem);
                                }
                                &.done {
                                    margin-right: 0.5rem;
                                }
                                &.active {
                                    border: 0.1rem solid $task-tab-button-border;
                                    z-index: 1;
                                }

                                svg {
                                    margin-right: 11px;
                                }

                                .tasks-counter {
                                    margin-left: 4px;
                                    border-radius: 0.15em;
                                    display: flex;
                                    width: 1.2rem;
                                    height: 1.2rem;
                                    justify-content: center;
                                    background-color: $task-counter-background-inactive;
                                    &.active {
                                        background-color: $task-counter-background-active;
                                    }
                                    color: $black-color-all-themes;
                                }
                                &.active {
                                    background-color: $white-color-all-themes;
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                &.tabs-hidden {
                    height: 100%;
                    .tab-container {
                        height: 100%;
                        ul {
                            overflow: auto;
                        }
                        .yonder-list {
                            height: calc(100% - 340px);
                            overflow: auto;
                            @include media-breakpoint-down("md") {
                                height: calc(100% - 270px);
                                overflow: auto;
                            }
                        }
                        .filter-dashboard {
                            &.opened {
                                position: absolute;
                                .filter-sort-options {
                                    .tabs {
                                        .tab-container {
                                            @include media-breakpoint-down("md") {
                                                height: 170px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dark {
    .tasks-tabs {
        .tasks-button {
            background-color: transparent !important;
            color: $white-color-all-themes;
            border: 1px solid $task-counter-background-inactive;
            &.active {
                color: $black-color-all-themes !important;
                background-color: $white-color-all-themes !important;
                border-color: $white-color-all-themes !important;
            }
        }
        .tasks-counter {
            &.active {
                background-color: $task-counter-background-active-dark !important;
            }
        }
    }
}
