.labeled-button {
  position: relative;

  .labeled-button-label {
    position: absolute;

    display: inherit;

    padding: $base-padding;

    font-size: 1em;

    opacity: 0;
    transition: opacity 0.2s 0.25s;

    z-index: $zindex-labeled-btn;
  }

  &.left {
    .labeled-button-label {
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.right {
    .labeled-button-label {
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.top {
    .labeled-button-label {
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
    }
  }

  &.bottom {
    .labeled-button-label {
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
    }
  }

  &.no-transition {
    .labeled-button-label {
      transition: none !important;
    }
  }

  &:hover {
    .labeled-button-label {
      opacity: 1;
      transition: opacity 0.2s 0.25;
    }
  }
}
