.storybooklet-page {
  padding: 1em;

  .storybooklet-page-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
  }

  .story {
    margin: 1em;
  }
}
