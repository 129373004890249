@import '~froala-editor/css/froala_style.min.css';
@import '~froala-editor/css/froala_editor.pkgd.min.css';
@import '~froala-editor/css/plugins.pkgd.min.css';
@import 'plugins/plugins';

$froala-editor-action-bar-height: 50px;
$froala-editor-action-bar-opened-height: $froala-editor-action-bar-height + 49px;
$froala-editor-toolbar-height: 39px;

.content-editor {
  height: calc(100% - 40px);
  width: 100%;
  margin: 0 auto;
  @include document-content;

  .fr-box {
    height: 100%;
    &.fr-basic {
      .fr-element {
        color: #414141;
      }
    }
    .fr-wrapper {
      // overwritten in AdjustEditorHeight.ts dynamically
      height: calc(100% - #{$froala-editor-action-bar-height + $froala-editor-toolbar-height});
      transition-property: all;
      transition-duration: 0.5s;

      .fr-view,
      .fr-code {
        height: 100% !important;
        overflow-y: auto;
      }

      .fr-image-resizer {
        display: none;
      }

      &.temp-revision {
        background-color: $temporary-revision-color;
      }
    }

    &.fr-code-view .fr-counter {
      display: block;
    }

    &.fr-toolbar-open {
      .fr-wrapper {
        height: calc(100% - #{$froala-editor-action-bar-opened-height + $froala-editor-toolbar-height});
        &.temp-revision {
          background-color: $temporary-revision-color;
        }
      }
    }
  }

  .dark-theme {
    &.fr-toolbar {
      &.fr-top {
        border-radius: 0px;
      }
    }
    &.fr-box {
      &.fr-basic {
        .fr-wrapper {
          background: #2f2f2f;
        }
        .fr-element {
          color: #fff;
        }        
      }
    }
  }

  .dynamic-content[data-dc-type='index'] {
    // Hide the generated part of dynamic content in Editing view (it's still visible within the code editor)
    .dynamic-content-generated {
      display: none;
    }
  }

  .dynamic-content-labels {
    display: block;
  }

  .dynamic-content-generated {
    td {
      padding: 2px 5px;
    }
    .dynamic-content-revision-bulletin {
      td {
        border: none;
      }

      .summary {
        font-weight: bold;
      }
    }
  }
}

.fr-toolbar {
  ul,
  ol {
    li {
      &:before {
        content: '' !important;
      }
    }
  }
}

.fr-popup {
  // Froala value
  z-index: 2147483645 !important;
  .fr-color-set.fr-selected-set {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 232px;
  }
}

.content-editor .def-aviation .fr-code,
.content-editor .def-aviation .fr-view,
.document-content.fr-view {
  /*Challenge response AH ready styles*/
  .challenge-response-layout {
    position: relative;
    display: block;
    width: 100%;
    font-size: 10pt;
    overflow: hidden;
    line-height: 15px;
    margin: 0px;
    padding: 0px;
    div {
      margin: 0px;
      padding: 0px;

      /* Overwrite uploaded stylesheet that add comments such as "Division" */
      /* This can be removed if div:before { ... } rules are not uploaded anymore in document-type.css */
      &:after,
      &:before {
        content: '';
        display: none; /* For Safari */
      }
    }

    /* Overwrite uploaded stylesheet that add comments such as "Division" */
    /* This can be removed if div:before { ... } rules are not uploaded anymore in document-type.css */
    &::before {
      content: '';
      display: none; /* For Safari */
    }
  }

  .challenge-response-layout .challenge-index {
    position: relative;
    width: 24px;
    height: 24px;
    display: block;
    float: left;
  }

  .challenge-response-layout.index-only .challenge-index span {
    padding-top: 0px;
    display: inline-block;
  }

  .challenge-response-layout .challenge-index span {
    padding-top: 6px;
    display: inline-block;
  }

  .challenge-response-layout .challenge-precaution-icon {
    position: relative;
    width: 24px;
    height: 24px;
    display: block;
    float: left;
  }
  .challenge-response-layout .challenge-precaution-icon .MuiSvgIcon-root {
    vertical-align: text-bottom;
  }

  .challenge-response-wrapper {
    position: relative;
    display: inline-block;
    margin-top: 0px;
    top: 0px;
    padding-left: 10px;
    width: calc(100% - 48px);
    overflow: initial;
    padding-top: 5px !important;
  }

  .challenge-response-layout.no-index .challenge-response-wrapper {
    width: 100%;
    padding-top: 0px !important;
  }

  .challenge-response-layout.precaution-only .challenge-response-wrapper {
    width: calc(100% - 24px);
  }

  .challenge-response-layout.index-only .challenge-response-wrapper {
    width: calc(100% - 24px);
    padding-top: 0px !important;
  }

  .challenge-response-layout.no-index.precaution-only .challenge-response-wrapper {
    padding-top: 5px !important;
  }

  .challenge-response-wrapper .leaders {
    width: 100%;
    z-index: -1;
    &:before {
      margin-bottom: 0px;
    }
    position: absolute;
    top: initial;
    display: block;
    left: 0;
    bottom: -2px;
  }

  .challenge-response-wrapper .leaders:before {
    display: block;
    float: left;
    width: 0;
    white-space: nowrap;
    letter-spacing: 1px;
    content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
  }

  .challenge-response-wrapper > div {
    margin: 0px;
    padding: 0px;
  }

  .challenge-response-wrapper > div span {
    margin: 0px;
    font-size: 10pt;
    overflow: hidden;
  }

  .challenge-response-left {
    position: relative;
    width: 100%;
    overflow-x: initial;
    z-index: 4;
  }

  .challenge-response-left .text-p {
    display: inline-block;
    text-align: left;
    z-index: 4;
    max-width: 40%;
    width: auto;
    padding-right: 0px;
  }

  .challenge-response-left .text-p span {
    text-indent: 0px;
    max-width: 100%;
    z-index: 4;
    display: inline;
    background: var(--boeing-background-color-bright);
  }

  .challenge-response-right {
    position: relative;
    width: 100%;
    display: inline-block;
    top: -14px;
    z-index: 9;
  }
  .challenge-response-right .text-p {
    width: 40%;
    display: inline;
    text-align: right;
    text-indent: 100px;
    float: right;
    padding-left: 30px;
  }

  .challenge-response-right .text-p span {
    text-indent: 0px;
    background: var(--boeing-background-color-bright);
  }

  .challenge-response-remark {
    position: absolute;
    left: 45%;
    width: 20%;
    bottom: 0px;
    overflow-x: hidden;
  }

  .challenge-response-remark span {
    background: var(--boeing-background-color-bright);
    width: auto;
  }

  .challenge-response-layout .precaution-box {
    position: relative;
    display: inline-block;
    width: calc(100% - 40px);
    bottom: -3px;
    margin-left: 48px;
  }

  .challenge-response-layout.precaution-only .precaution-box {
    position: relative;
    display: inline-block;
    width: 100%;
    bottom: -3px;
    margin-left: 24px;
  }

  .precaution-box .precaution-box-wrapper {
    position: relative;
    display: inline-block;
    padding: 0.5em;
    width: auto;
    max-width: calc(100% - 30px);
    background: var(--boeing-precaution-box-color);
    border: 1px solid var(--boeing-border-color);
    margin-left: 0px;
    z-index: 7;
  }

  .precaution-box .left-line {
    position: absolute;
    left: -12px;
    top: 13px;
    color: var(--boeing-border-color);
    border-top: 1px solid var(--boeing-border-color);
    width: 10px;
    height: 1px;
  }

  .precaution-box .bottom-line {
    position: absolute;
    left: -12px;
    top: 13px;
    border-left: 1px solid var(--boeing-border-color);
    display: block;
    width: 1px;
    height: calc(100% - 10px);
    z-index: 7;
  }

  .light {
    width: 90px;
    height: 40px;
    display: table;
    padding: 2px 4px;
    border: 1px solid;
    text-transform: uppercase;
    text-align: center;
    line-height: normal;
    margin: 0px;
    span {
      display: table-cell;
      vertical-align: middle;
    }
    &::before {
      content: '';
      margin-bottom: 0;
    }
    &.bg-black {
      background-color: #000;
    }
    &.bg-blue {
      background-color: blue;
    }
    &.bg-yellow {
      background-color: #ffd400;
    }
    &.font-black {
      color: #000;
    }
    &.font-orange {
      color: #ff9900;
    }
    &.font-white {
      color: white;
    }
    &.font-red {
      color: red;
    }
  }

  .choice-items {
    &::before {
      content: '';
      margin-bottom: 0;
    }
    br {
      display: none;
    }
    div {
      &::before {
        content: '';
        margin-bottom: 0;
      }
    }

    .choice-item {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0;
      .condition-line {
        position: absolute;
        width: 1px;
        height: 100%;
        left: 11px;
        background-color: var(--boeing-condition-line-color);
        margin: 0;
        padding: 0;
        top: 12px;
      }
      .condition-statement {
        &::before {
          content: '◆';
          color: var(--boeing-condition-line-color);
          height: 15px;
          width: 15px;
          font-size: 13pt;
          display: inline-block;
        }
      }
      .condition-content {
        padding: 10px 0 10px 50px;
      }
      &:last-child {
        .condition-line {
          height: 0;
        }
      }
    }
  }

  .dynamic-content-generated {
    td {
      padding: 2px 5px;
    }
  }
}

.module-version-content {
  .challenge-response-right {
    z-index: 4;
  }
}

.command-precaution-wrapper {
  position: relative;
  display: block;
  width: 100%;
  font-size: 10pt;
  overflow: hidden;
  line-height: 15px;
  margin: 0px !important;
  padding: 0px !important;
  &:before,
  &:after,
  div::before,
  div::after {
    content: '' !important;
    display: block !important;
    font-size: 8pt !important;
    color: var(--boeing-border-color) !important;
    margin-bottom: 0px !important;
  }
  .precaution {
    position: relative;
    display: inline-block;
    width: calc(100% - 0px);
    bottom: -14px;
    margin-left: 27px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding: 0px !important;
    .left-line {
      position: absolute;
      left: -4px;
      top: 13px;
      color: var(--boeing-border-color);
      border-top: 1px solid var(--boeing-border-color);
      width: 16px;
      height: 1px;
    }
    .bottom-line {
      position: absolute;
      left: -4px;
      top: 13px;
      border-left: 1px solid var(--boeing-border-color);
      display: block;
      width: 1px;
      height: calc(100% - 10px);
      z-index: 7;
    }
  }
  .precaution-text {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 4px;
    max-width: calc(100% - 30px);
    background: var(--boeing-precaution-box-color);
    border: 1px solid var(--boeing-border-color);
    margin-left: 5px;
    z-index: 7;
    margin-top: 2px;
    margin-bottom: 2px;
    p {
      margin-bottom: 0px !important;
      margin-top: 0px;
    }
  }
  .command-text {
    position: relative;
    padding: 14px 0px 0px 6px !important;
    margin: 0px;
    p {
      padding-left: 35px !important;
      margin-top: 0px;
    }
  }
  .precaution-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    float: left;
    padding: 0px !important;
    margin: 0px !important;
    top: 8px;
    left: 12px;
    z-index: 9;
  }
}
