.reader {
  position: relative;
  height: calc(100% + #{$container-padding * 2});
  margin: -#{$container-padding};
  overflow-y: auto;
  min-height: 100%;
  width: calc(100% + #{$container-padding * 2});
  transition-property: all;
  transition-duration: 0.5s;
  outline: 0;

  .highlight {
    background-color: yellow !important;
  }

  .search-result-link {
    text-decoration: none;
  }

  &.module-view-open {
    & > .MuiGrid-container {
      & > div:first-child {
        .react-swipeable-view-container {
          height: 0px !important;
          opacity: 0 !important;
        }
      }
    }
  }

  @include media-breakpoint-up('sm') {
    margin-left: #{$sidebar-width - $container-padding};

    &.tools-open {
      margin-left: #{$sidebar-open-width - $container-padding};
    }
  }

  & > .MuiGrid-container {
    min-height: 100%;

    & > div:first-child {
      overflow-y: hidden;
      width: 100%;

      .react-swipeable-view-container {
        min-height: 100%;
      }

      .MuiGrid-item {
        padding: #{$container-padding * 2} !important;
      }
    }

    .reader-content {
      flex-grow: 1;
      height: 100%;

      .module-version-content {
        background-color: $background-color-bright;
        &.temp-revision {
          background-color: $temporary-revision-color;
        }
        img {
          background-color: white;
          &:hover {
            cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z'/%3E%3C/svg%3E")
                15 15,
              pointer;
          }
        }
      }

      .module-version-overview {
        background-color: $background-color-bright;
        margin-top: #{$container-padding * 2};

        .module {
          border-right: none;
        }

        .MuiList-root {
          padding: 0;
        }

        .MuiListItem-root {
          cursor: pointer;
          padding-left: 0;
          padding-right: 0;
          margin-left: $base-padding * 2;
          width: calc(100% - #{$base-padding * 2});
        }

        .MuiDivider-root {
          margin-left: $base-padding * 2;
        }
      }

      .tags {
        justify-content: flex-end;

        .tag {
          height: 16px;
          font-size: 12px;

          svg[class^='MuiSvgIcon-root'] {
            font-size: 9px;
          }
        }
      }

      .search-result-link {
        text-decoration: none;
      }

      .module {
        border-right: #{$border-width * 3} $border-type $border-color;

        &.temp-revision {
          background-color: $temporary-revision-color;
        }

        &.focused {
          background-color: rgb(225, 225, 225);

          .content {
            * {
              background-color: inherit;
            }
          }
        }

        &:first-child {
          padding-top: $base-padding;
          border-radius: 0 $border-radius 0 0;
        }

        &:not(:first-child) {
          margin-top: $base-padding;
        }

        &:last-child {
          border-radius: 0 0 $border-radius 0;
        }

        &:first-child:last-child {
          border-radius: $border-radius;
        }

        .header {
          display: flex;
          flex-direction: column;
          padding: 0 $container-padding 0 $reader-side-padding;

          .module-version-title {
            padding-right: #{$reader-side-padding - $container-padding};
          }

          .tags {
            .tag {
              margin-left: 4px;
              margin-bottom: 4px;
              background-color: $background-color-bright;
            }
          }
        }

        .content {
          .rtl {
            text-align: right;
          }
          padding: 0 $reader-side-padding;

          @include document-content;
        }

        &.changed, &.draft {
          border-right-color: var(--reader-changed-color);
        }

        .header {
          justify-content: right;
          display: flex;
          align-items: center;

          .title {
            width: 100%;
          }
        }
      }

      .reader-warning {
        background-color: orange;
        margin-top: 10px;
        padding-left: 5px;
      }

      .dynamic-content {
        &[data-dc-grouped='true'] {
          .dynamic-content-generated {
            > div {
              &:first-child {
                padding-top: 0px;
              }
            }
          }
        }
        .dynamic-content-generated {
          display: block;
          > div {
            cursor: pointer;
            font-size: 1rem;
            padding-left: 0;
            padding-right: 0;
            margin-top: 4px;
            margin-left: $base-padding * 2;
            width: calc(100% - #{$base-padding * 2});

            a {
              text-decoration: none !important;
            }

            .title {
              color: $text-color;
            }

            .pageref {
              color: $text-color;
            }
            &.dynamic-content-index-entry {
              padding: 8px 0px;
              border-bottom: 1px solid $border-color;
              a {
                display: inline-block;
                margin-top: 4px;
                margin-bottom: 4px;
              }
              &:last-child {
                border-bottom-width: 0px;
              }
            }
            &.dynamic-content-index-group-label {
              padding-top: 30px !important;
              margin-left: 16px;
              font-weight: 600;
            }
          }

          .dynamic-content-temporary-changes {
            td {
              padding: 2px 5px;
            }
          }

          .dynamic-content-revision-bulletin {
            td {
              border: none;
            }

            .summary {
              font-weight: bold;
            }
          }
        }
      }
      .dynamic-content-labels {
        display: none;
      }
    }
  }
  &.font-size {
    &--small {
      .document-content,
      .module-version-title {
        zoom: 0.8;
      }
    }
    &--default {
      .document-content,
      .module-version-title {
        zoom: 1;
      }
    }
    &--medium {
      .document-content,
      .module-version-title {
        zoom: 1.2;
      }
    }
    &--big {
      .document-content,
      .module-version-title {
        zoom: 1.5;
      }
    }
  }
}

.yarl__slide_image {
  cursor: grab;
  background-color: white;
  &:active {
    cursor: grabbing;
  }
}
