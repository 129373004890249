$history-log-margin: 2em;

.global-history-log {
  width: 100%;
  padding: $history-log-margin;

  .global-history-log-filter-configuration {
    display: flex;
    flex-direction: column;

    .global-history-log-selected-title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }

    .global-history-log-filter-actions {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      margin: 0 0 2em 0;

      .global-history-log-document-selector {
        display: flex;
        justify-content: flex-start;

        .global-history-log-clear-button {
          margin-left: 2em;
          display: flex;
          gap: 1em;
        }
      }

      .global-history-log-export-button-container {
        display: flex;
        justify-content: flex-end;
        width: 100px;

        button {
          padding: 0;
          min-width: 40px;
          box-shadow: none;
          .MuiButton-startIcon {
            margin: 0;
            svg {
              fill: $text-color-light;
            }}

            &:hover {
              box-shadow: none;
            }
        }

        .MuiCircularProgress-colorPrimary {
          color: $text-color-light;
        }
      }
    }
  }

  .history-log {
    height: calc(100% - 7em);
  }
}
