$navbar-env-height: calc(#{$navbar-height} + #{env(safe-area-inset-top)});

.screen-orientation {
  .navbar {

    header {
      height: $navbar-env-height;
      margin: 0 #{-$base-padding};
      padding: 0 $base-padding;
      width: calc(100% + #{2 * $base-padding});

      .MuiToolbar-root {
        min-height: 100% !important;
        align-items: flex-end;
      }

      .center {
        min-height: 48px;
        display: flex;
        align-items: center;
      }
    }
  }

  .menu > .MuiPaper-root > .container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .layout:not(.layout--dashboard) > .container {
    height: 100%;

    .sidebar {
      top: $navbar-env-height;

      &__open-btn {
        top: calc(#{$navbar-height + $container-padding} + #{env(safe-area-inset-top)});
      }
    }

    .drawer {
      top: $navbar-env-height;
    }

    .module-view {
      height: 100%;
    }

    .acknowledge-toast {
      bottom: calc(#{$base-padding} + #{env(safe-area-inset-bottom)});
    }
  }

  .subscreen {
    top: env(safe-area-inset-top);
  }

  .search-history {
    margin: $navbar-env-height 0 0 0;
    min-height: calc(100% - #{$navbar-env-height});
  }

  &.left {
    .menu > .MuiPaper-root > .container {
      padding-left: env(safe-area-inset-left);
    }

    .layout > .container {
      margin-left: env(safe-area-inset-left);
      width: calc(100% - #{env(safe-area-inset-left)});

      .sidebar:not(.sidebar--workflow) {
        min-width: calc(#{$sidebar-width} + #{env(safe-area-inset-left)});

        .container {
          width: $sidebar-width;
          margin-left: auto;
        }
      }

      .drawer:not(.hidden) {
        margin-left: $sidebar-width;
        left: env(safe-area-inset-left);
      }

      .module-view {
        @include media-breakpoint-up('sm') {
          left: calc(#{$sidebar-width} + #{env(safe-area-inset-left)});
          max-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-left)});
          min-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-left)});

          &.sidebar-open {
            left: calc(#{$sidebar-open-width} + #{env(safe-area-inset-left)});

            max-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-left)});
            min-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-left)});
          }
        }

        @include media-breakpoint-up('md') {
          &.sidebar-open {
            left: 0
          }
          left: 0
        }
      }
    }
  }

  &.right {
    .layout > .container {
      margin-right: calc(#{env(safe-area-inset-right)} - #{$container-padding * 2});
      width: calc(100% - #{env(safe-area-inset-right)} + #{$container-padding * 2});

      @include media-breakpoint-up('md') {
        margin-right: calc(#{env(safe-area-inset-right)});
        width: calc(100% - #{env(safe-area-inset-right)});
      }

      .module-view {
        @include media-breakpoint-up('sm') {
          max-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-right)} + #{$container-padding * 2});
          min-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-right)} + #{$container-padding * 2});

          &.sidebar-open {
            min-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-right)} + #{$container-padding * 2});
            max-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-right)} + #{$container-padding * 2});
          }
        }

        @include media-breakpoint-up('md') {
          max-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-right)});
          min-width: calc(100vw - #{$sidebar-width} - #{env(safe-area-inset-right)});

          &.sidebar-open {
            min-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-right)});
            max-width: calc(100vw - #{$sidebar-open-width} - #{env(safe-area-inset-right)});
          }
        }
      }
    }

    .subscreen {
      .layout > .container {
        margin-right: 0;
        width: 100%;

        .subscreen-content > .paper {
          margin-right: calc(env(safe-area-inset-right) - #{$container-padding * 2});
        }
      }
    }
  }

  &.left,
  &.right {
    .layout--dashboard > .container {
      padding-top: calc(64px + env(safe-area-inset-top));
      margin-right: env(safe-area-inset-right);
      margin-left: env(safe-area-inset-left);
      width: calc(100% - #{env(safe-area-inset-right)} - #{env(safe-area-inset-left)});
    }
  }
}
