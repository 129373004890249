$module-view-header-height: 50px;
$module-view-tabs-height: 48px;

.module-view {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100vw;
  min-width: 100vw;
  height: 100%;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition-property: all;
  transition-duration: 0.3s;
  z-index: $zindex-moduleview;

  &.closed {
    top: -100%;
    right: 0;
    transition: height ease-out 0.3s;
  }

  @include media-breakpoint-up('sm') {
    max-width: calc(100vw - #{$sidebar-width});
    min-width: calc(100vw - #{$sidebar-width});

    &.sidebar-open {
      left: $sidebar-open-width;
      min-width: calc(100vw - #{$sidebar-open-width});
      max-width: calc(100vw - #{$sidebar-open-width});
      left: 0;
      position: absolute;
    }
  }

  .paper {
    display: flex;
    flex-direction: column;
    margin: #{$container-padding * 2} #{$container-padding * 2} 0 #{$container-padding * 2};
    height: calc(100% - #{$container-padding * 2});
    border-bottom: none;
    border-radius: $border-radius $border-radius 0 0;
    width: calc(100% - #{$container-padding * 4});

    .module-view-header {
      display: flex;
      align-items: center;
      border-bottom: $default-border;
      min-height: $module-view-header-height;
      padding: 0 #{$base-padding * 2};

      .title {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow-x: hidden;
      }

      .favorite {
        margin-right: 5px;
      }

      .MuiChip-root {
        margin-right: 5px;

        &.invalid {
          color: $invalid-color;
          border-color: $invalid-color;

          .MuiChip-avatar {
            background-color: $invalid-color;
            color: $background-color-bright;
          }
        }

        &.draft {
          color: $draft-color;
          border-color: $draft-color;

          .MuiChip-avatar {
            background-color: $draft-color;
            color: $background-color-bright;
          }
        }
      }
    }

    .module-view-content {
      position: relative;
      display: inline-flex;
      justify-content: flex-start;
      flex-direction: column;
      flex-shrink: 0;
      overflow: auto;

      background-color: $background-color-bright;
      height: calc(50% - #{$module-view-header-height});
      // padding: #{$container-padding * 2};

      @include document-content;

      .split-view-button {
        position: absolute;
        right: 2 * $container-padding;
        bottom: $container-padding;
        background-color: $background-color-bright;

        svg {
          transition: color 0.15s ease;
        }

        &:hover {
          svg {
            color: $black-color-all-themes;
          }
        }
      }

      &.temp-revision {
        background-color: $temporary-revision-color;
      }
    }

    .module-tabs {
      min-height: 50%;
    }

    .MuiTabs-scroller {
      height: $module-view-tabs-height;
    }
  }
}

.module-view-tool {
  position: relative;

  min-height: 100%;
  width: 100%;
  height: 100%;

  padding: 0;

  .yonder-button {
    &--fab {
      position: absolute;
      right: #{$container-padding * 2};
      bottom: #{$container-padding * 2};
      svg {
        fill: $white-color-all-themes;
      }
    }
  }

  ul {
    max-height: 100%;
    overflow-y: auto;
    padding: 0;
  }

  &.context-tool {
    .context-occurrences {
      padding-left: 20%;

      .MuiListItem-root {
        cursor: pointer;
      }
    }

    .context-item {
      cursor: pointer;

      .title {
        color: $reader-default-color;
      }

      .path-segment {
        color: $path-segment-color;

        &:not(:first-child) {
          &::before {
            content: '/';
            position: relative;
            padding: 0 4px;
          }
        }
      }

      .content {
        color: $path-segment-color;
      }
    }
  }

  &.links-tool {
    .list-item .MuiTypography-root {
      font-weight: 500;
    }

    .list-divider {
      margin-left: $base-padding * 2;
      width: calc(100% - #{$base-padding * 2});
    }

    .links-list {
      padding-left: $base-padding * 2;
    }
  }

  &.change-request-tool {
    .statusbar {
      .MuiToolbar-root {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
