.subscreen {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: $zindex-subscreen;
  min-width: 100vw;
  max-width: 100vw;

  &.closed {
    display: none;
  }

  .layout {
    position: relative;
    width: 100%;
    height: 100%;

    & > div {
      padding: 0;
    }

    .navbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .sidebar {
      position: absolute;
      top: $navbar-height;
      left: 0;
      min-width: $sidebar-open-width;
      max-width: $sidebar-open-width;
      height: 100%;
      border-right: $default-border;
      padding-bottom: 0;

      .sidebar-tool {
        align-items: center;

        .MuiDivider-root {
          width: 100%;
        }
      }

      .sidebar-tool-header {
        width: 80%;

        * {
          padding: 0;
        }
      }

      .sidebar-tool-body {
        width: 100%;
      }

      .container {
        padding: $card-padding 0 0;

        .button {
          min-width: 100%;
        }
      }

      & ~ .subscreen-content {
        width: calc(100% - #{$sidebar-open-width});
        margin-left: $sidebar-open-width;
      }
    }

    .subscreen-content {
      position: absolute;
      top: $navbar-height;
      right: 0;

      display: flex;
      flex-grow: 1;
      height: 100%;
      width: 100%;

      padding: 2 * $container-padding;
      padding-bottom: 0;

      background-color: $background-color;

      .MuiPaper-root {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        border-bottom: 0;
        border-radius: $border-radius $border-radius 0 0;
      }
    }
  }
}

.module-version-subscreen {
  .subscreen-content {
    .split-view {
      height: 100%;
    }
  }
  .MuiPaper-rounded{
    border-top: 0;
  }

  .module-version-header{
    border-top: 1px solid var(--reader-border-color);
  }

  .yonder-spacer{
    background-color: var(--reader-background-color);
  }

}
