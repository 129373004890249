.layout--dashboard {
  .container {
    @include media-breakpoint-down('md') {
      height: auto;
      min-height: auto;
    }
  }

  .dashboard {
    &__column {
      height: 100%;
      flex-wrap: nowrap !important;
      display: flex;
      flex-direction: column;
      padding: 0 !important;

      &.two-items-xs {
        @include media-breakpoint-up('md') {
          .dashboard__item {
            height: 50%;
          }
        }
      }

      &.two-items-md {
        @include media-breakpoint-between('md', 'lg') {
          .dashboard__item {
            height: 50%;
          }
        }
      }

      &:first-child {
        @include media-breakpoint-up('md') {
          flex-direction: row;
        }

        &.has-workflow {
          flex-direction: column;

          @include media-breakpoint-up('lg') {
            flex-direction: row;
          }
        }
      }
    }

    &__item {
      height: 100%;

      @include media-breakpoint-down('md') {
        height: calc(100vh - #{$navbar-height + $dashboard-card-header-height + $dashboard-card-tab-height});
        min-height: 250px;
      }
    }
  }
}
