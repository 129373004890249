.react-swipeable-view-container {
  height: auto !important;

  div[data-swipeable='true'] {
    position: relative;
    transition: height ease 350ms;

    &[aria-hidden='false'] {
      height: auto;
      overflow-y: auto;
    }

    &[aria-hidden='true'] {
      height: 0;
      overflow-y: hidden;
    }
  }
}
