$sidebar-width: 64px;
$sidebar-open-width: 332px;
$drawer-width: $sidebar-open-width - $sidebar-width;

$navbar-height: 54px;
$statusbar-height: 20px;
$module-version-header-height: 50px;

$dashboard-card-header-height: 85px;
$dashboard-card-tab-height: 56px;

$container-icon-min-width: 56px;

$base-padding: 8px;
$container-padding: 10px;
$paragraph-margin: 8px;
$title-margin: 10px;

$button-margin: 16px;
$card-margin: 15px;
$card-padding: 14px;
$icon-padding: 12px;

//Manually take height from inspector if something is changed here
$version-wrapper-height: 130px;

$spinner-margin: $card-margin;

$reader-side-padding: $container-padding * 4;
$reader-default-color: var(--reader-theme-default);
$reader-default-color-invert: var(--reader-theme-default-invert);
$white-color-all-themes: var(--reader-white-color);
$dark-gray-color-all-themes: var(--reader-dark-gray-color);
$light-gray-color-all-themes: #ededed;
$black-color-all-themes: var(--reader-black-color);
$blue-color-all-themes: var(--reader-blue-color);
$blue-color-faded: rgba(92, 168, 243, 0.3);
$blue-icon: #3ca3ff;
$background-color: var(--reader-background-color);
$background-color-bright: var(--reader-background-color-bright);
$background-color-bright-hover: var(--reader-background-color-bright-hover);
$background-light-blue-color: var(--reader-background-light-blue-color);
$main-ui-color: var(--reader-main-ui-color);
$light-ui-color: var(--reader-light-ui-color);
$text-color: var(--reader-text-color);
$text-color-light: var(--reader-text-color-light);
$path-segment-color: var(--reader-path-segment-color);
$cr-button-text-color: var(--reader-cr-button-text-color);
$cr-button-border-color: var(--reader-cr-button-border-color);
$chip-background-color: var(--reader-chip-background-color);

$diff-insertion-color: var(--diff-insertion-color);
$diff-deletion-color: var(--diff-deletion-color);

$border-width: 1px;
$border-type: solid;
$border-color: var(--reader-border-color);
$border-radius: 4px;
$default-border: $border-width $border-type $border-color;

$draft-color: cornflowerblue;
$invalid-color: var(--reader-invalid-color);
$error-color: var(--reader-error-color);
$error-color-background: rgba(176, 0, 32, 0.3);
$ok-color: var(--reader-ok-color);
$ok-color-background: var(--reader-ok-color-background);
$warning-color: #ff9800;
$search-error-color: #ffa206;
$highlight-text-color: var(--reader-highlight-text-color);
$module-version-background-color: var(--reader-module-version-item-background);

$admin-bar-background: var(--reader-admin-bar-background);

$inactive-tab-icon-color: var(--reader-inactive-tab-icon-color);
$button-not-active-color: var(--reader-button-not-active-color);

$temporary-revision-color: var(--reader-temporary-revision-color);

$warning-color-change-request: #fbcc24;

$counter-background: var(--counter-color);
$counter-background-active: var(--counter-active-color);

$icon-hover-bg-color: rgba(#808080, 0.15);
$icon-not-active-state: #808080;

$active-favorite-group-list-item-color: var(--favorite-group-item-active);
$kebab-menu-border-color: #979797;

$task-tab-button-border: #817f7f;
$task-tab-button-background: #d9d9d9;
$task-counter-background-active: rgba(126, 177, 238, 0.37);

$task-tab-button-background-inactive-dark: #d9d9d9;
$task-tab-button-background-active-dark: #2a2a2a;
$task-counter-background-active-dark: #cbdef4;
$task-counter-background-inactive: #b4b4b4;

$task-list-item-bg: rgb(217, 232, 250);
$task-list-item-bg-dark: #99c7ff;
$task-list-item-tertiary-color-dark: #3d58e7;
$task-list-item-secondary-color-dark: #606060;
$acknowledge-footer-bg-dark: #bbd5f5;

$survey-border: rgba(0,0,0,0.12);
$survey-error-color: #b00020;
$survey-container-offset: var(--survey-container-offset, 220px);
$submission-status-color-1: #E55B5B;
$submission-status-color-2: #bd71bd;
$submission-status-color-3: #498FF9;
$submission-status-color-4: #3a98a9;
$submission-status-color-5: #549b4f;
$submission-status-color-6: #ce7915;
$submission-status-color-7: #b2786c;
$submission-status-color-8: #808080;

$grid-breakpoints: (
  xs: 480px,
  sm: 680px,
  md: 960px,
  lg: 1280px,
);

$tabs-header-height: 46px;
$tabs-tab-height: 50px;

$bubble-size: 10px;
$bubble-color: $main-ui-color;
$bubble-radius: 50%;

$avatar-size: 60px;

$search-field-container-height: 64px;

$fab-button-size: 56px;

$zindex-statusbar: 0;
$zindex-moduleview: 1;
$zindex-sidebar-open-btn: $zindex-moduleview + 1;
$zindex-toast: 3;
$zindex-labeled-btn: 4;
$zindex-toolbar: 101;
$zindex-sidebar: 9;
$zindex-drawer: $zindex-sidebar - 1;
$zindex-subscreen: 1101;
