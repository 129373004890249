$search-result-space-top: 20px;
$search-result-space-bottom: 16px;
$search-result-space-content: 4px;
$search-results-padding: $container-padding * 4;
$search-results-padding-top: $search-results-padding - $search-result-space-top;
$search-results-padding-bottom: $search-results-padding - $search-result-space-bottom;

.search-results {
  height: calc(100% + #{$container-padding * 2});
  margin: -#{$container-padding};
  overflow-y: auto;
  justify-content: flex-start;
  min-height: 100%;
  width: calc(100% + #{$container-padding * 2});
  transition-property: all;
  transition-duration: 0.5s;

  @include media-breakpoint-up('sm') {
    margin-left: #{$sidebar-width - $container-padding};

    &.tools-open {
      margin-left: #{$sidebar-open-width - $container-padding};
    }
  }

  .search-results-list {
    padding: #{$search-results-padding-top} 0 #{$search-results-padding-bottom};
    min-height: 100%;
    height: 100%;

    .yonder-infinity-list-container {
      margin-top: -$search-results-padding-top;
      height: calc(100% + #{$search-results-padding-top + $search-results-padding-bottom});

      .yonder-infinity-list {
        padding: 0 #{$search-results-padding};
      }
    }

    .search-result-link {
      text-decoration: none;
      width: 100%;
    }

    .divider {
      margin-left: 0;
      width: 100%;
    }

    .search-result {
      margin-left: 0;
      padding-left: 0;
      width: 100%;
      background-color: $background-color;
      margin-top: $search-result-space-top;
      margin-bottom: $search-result-space-bottom;
      cursor: pointer;

      .search-result-title {
        @include module-title;
        margin-top: 0;

        &.h3 {
          font-weight: 500;
          line-height: 100%;
        }

        .text {
          color: $blue-color-all-themes;
          margin-bottom: $search-result-space-content;
        }
      }

      .search-result-path {
        margin-bottom: $search-result-space-content;
        text-transform: uppercase;

        p {
          margin: 0;
        }
      }

      .search-result-content {
        p {
          margin: 0;
          font-size: 13px;
          line-height: 120%;
        }
      }
    }

    .highlight {
      background-color: yellow !important;
      color: $highlight-text-color;
    }
  }
}

.drawer {
  .type-filter {
    .type-filter-button {
      font-size: 80%;

      &.active {
        background-color: $background-light-blue-color;
      }
    }
  }

  .sort-selection {
    padding-top: $base-padding;
    .sort-button {
      font-size: 80%;

      &.active {
        background-color: $background-light-blue-color;
      }
    }
  }
}

.layout--search-results {
  .drawer {
    .tool {
      &:not(:first-child) {
        margin-top: $container-padding * 3;
      }
    }
  }
}
