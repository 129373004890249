.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $border-color;
  margin: 1em 0;
  .search-bar-icon {
    display: flex;
    margin-right: 0.4em;
    margin-left: 0.4em;
    color: $dark-gray-color-all-themes;
    position: absolute;
    left: 0;
    top: 0.2em;
  }
  input {
    padding-left: 2em;
  }
  .menu-button-right {
    position: absolute;
    right: 0;
  }
}

.dark {
  .search-bar-icon {
    color: $light-gray-color-all-themes;
  }
}
