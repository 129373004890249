%navbar-buttons {
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
}

%navbar-buttons-positioning-left {
  margin-left: -20px;
}

%navbar-buttons-positioning-right {
  margin-right: -20px;
}
