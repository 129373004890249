.widget-card {
  display: flex;
  flex-direction: column;
  min-width: 40em;
  min-height: 40em;
  width: 100%;

  .widget-card-title {
    padding: 2em 2em 0 2em;
    font-weight: bold;
  }

  .widget-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }
}
