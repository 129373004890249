.yonder-snackbar {
  &.absolute {
    position: absolute;
    right: $base-padding * 3;
    left: auto;
    bottom: $base-padding * 3;
    transform: none;
  }

  &.in-module-content {
    position: absolute;
    left: 20px;
    bottom: 50px;
    transform: none;
    margin-right: 80px;
  }

  .yonder-snackbar__message {
    &.info {
      background-color: $background-color;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.error {
      background-color: $error-color;
    }

    &.success {
      background-color: $ok-color;
    }

    .message {
      display: flex;
      align-items: center;

      .icon {
        margin-right: $base-padding;
      }
    }
  }
}
