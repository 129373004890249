.yonder-button {
  font-weight: bold;
  letter-spacing: 2px;

  &--contained:not(.Mui-disabled) {
    background-color: $background-color-bright;
    border-width: 0.5px !important;
    border-style: $border-type !important;
    border-color: $border-color !important;
  }

  &--outlined:not(.Mui-disabled) {
    border-color: $main-ui-color;
  }

  &--speed-dial.MuiSpeedDial-fab:not(.Mui-disabled),
  &--fab:not(.Mui-disabled) {
    background-color: $main-ui-color;

    &:hover {
      background-color: $light-ui-color !important;
    }

    .MuiSvgIcon-root {
      svg {
        fill: $background-color-bright-hover;
      }
    }
  }

  &--speed-dial {
    .MuiSpeedDial-root {
      align-items: flex-end;
    }

    .MuiSpeedDialIcon-icon {
      fill: $white-color-all-themes;
    }
    .MuiSpeedDial-actions {
      align-items: flex-end;

      .MuiSpeedDialAction-staticTooltipLabel {
        position: static;
        white-space: pre;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $light-ui-color !important;
    }
  }

  &:not(.Mui-disabled) {
    color: $cr-button-text-color;
    svg {
      fill: $white-color-all-themes;
    }
    &:hover {
      background-color: $background-light-blue-color;
    }
  }
}

.menu-button-left {
  svg {
    fill: $highlight-text-color;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $blue-color-all-themes !important;
}
