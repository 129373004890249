.highly-performant-table-cell {

  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
  }

  &:hover {
    padding: 0;

    & > span {
      contain: paint;
      display: inline-flex;
      padding: 6px 16px 6px 16px;
      min-width: 100%;
      background-color: #ececec;
    }

    .dark & > span {
      background-color: #2a2a2a;
    }
  }
}

.highly-performant-table-cell-expanded {
  width: 100px;
  &:hover {
    & > span {
      float: right;
    }
  }
}
