.module-version-highlighter {
  width: 100%;
  max-height: 65vh;
  overflow-y: auto;

  .content {
    overflow-x: hidden;

    @include document-content;
  }
}

.module-version-content {
  .delta-content-inserted {
      background-color: $diff-insertion-color !important;
      text-decoration: underline;
      font-size: 1em;
      img {
        border: 0.7rem solid $diff-insertion-color;
      }
      th, td {
        background-color: $diff-insertion-color !important;
      }
  }

  .delta-content-deleted {
      background-color: $diff-deletion-color !important;
      text-decoration: line-through;
      font-size: 1em;
      img {
        border: 0.7rem solid $diff-deletion-color;
      }
      th, td {
        background-color: $diff-deletion-color !important;
      }
  }

  // Always show a space after a deletion to separate it from a following insertion
  span.delta-content-deleted:after,
  strong.delta-content-deleted:after,
  em.delta-content-deleted:after,
  u.delta-content-deleted:after {
     display: inline-block;
     content: "\00a0";
     background-color: $background-color;
     text-decoration-color: none;
  }

  // Single diff summary items
  .delta-summary *:before {
     font-size: 0.7rem;
     display: inline-block;
     margin-right: 0.5rem;
     padding: 1px 4px 1px 4px;
     color: $text-color;
     }

  .delta-summary-insertions:before {
     content: "Content added";
     background-color: $diff-insertion-color;
  }

  .delta-summary-deletions:before {
     content: "Content removed";
     background-color: $diff-deletion-color;
  }

  .delta-summary-styling:before {
     content: "Formatting changed (unmarked)";
  }

  .delta-summary-whitespace:before {
     content: "Spaces changed (unmarked)";
  }

  .delta-summary-crash:before {
    content: "Content comparison was not possible";
    background-color: $diff-deletion-color;
    font-weight: bold;
  }

  .delta-summary-no-changes:before {
     content: "No changes";
     font-weight: bold;
   }

  // legacy styles for non-migrated change requests
  ins {
    background-color: $diff-insertion-color;
    img {
      border-left: 1px solid $diff-insertion-color;
    }
  }

  del {
    background-color: $diff-deletion-color;
    img {
      display: none !important;
    }
  }
}

// The delta summary is part of the diffContent XHTML, but it is moved above the module title in the
// several diffing views
.module-version-content {
  .delta-summary {
    color: darkgray;
    position: absolute;
    padding: 0;
  }
}

// Delta summary in module diffing view
.subscreen-content .delta-summary {
  top: -15px;
  right: 2rem;
}

// Summary in tasks view
.my-tasks-view .delta-summary {
 top: -15px;
 right: 0.5rem
}

// Summary in CR view
.change-request__split-view .delta-summary {
 top: -15px;
 right: 1rem
}

// The first real content after the summary should not have a margin above
.delta-result > *:nth-child(2) {
  margin-top: 0 !important;
}

.auto-cr-legal + * .delta-summary {
  display: none;
}
.auto-cr-legal + * .delta-content-deleted {
  display: none;
}
.auto-cr-legal + * .delta-content-inserted {
  background-color: inherit !important;
  text-decoration: none;
}
