.acknowledge-toast.acknowledge-toast-root {
  margin-top: $base-padding;
  z-index: $zindex-toast;

  .acknowledge {
    display: flex;
    padding: $base-padding;
    border: $border-width $border-type $main-ui-color;
    color: $main-ui-color;
    background-color: $background-light-blue-color;

    .acknowledge-content {
      display: flex;
      flex: 1;

      .acknowledge-button {
        font-size: 12px;
      }

      .acknowledge-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $paragraph-margin;

        .acknowledge-title {
          font-weight: bold;
        }
      }

      @include media-breakpoint-down('sm') {
        flex-direction: column-reverse;

        .acknowledge-text {
          margin-bottom: $paragraph-margin;
        }
      }
    }

    .acknowledge-actions {
      display: flex;
      align-items: center;

      .labeled-button {
        width: 50px;
        padding: 4px;

        .labeled-button-label {
          width: 75px;
          text-align: center;
          padding-right: 0;
          font-size: 0.8em;
          color: white;
          background-color: rgba(50, 50, 50, 0.75);
        }
      }
    }
  }
}
