#fr-plugin__lights {
  .MuiFormControl-root {
    &.select-color,
    &.select-bg {
      height: 46px;
      margin: 0px;
      border-radius: 2px;
      border: 1px solid rgba(34, 34, 34, 0.36);
      margin-bottom: 16px;
      .MuiInputLabel-formControl {
        top: -8px;
        left: 14px;
        background: #fff;
      }
      .MuiInputBase-root {
        &:before {
          border-bottom: 0px;
        }
        &.Mui-focused {
          .MuiNativeSelect-root {
            background-color: transparent;
          }
        }
      }
      label + .MuiInput-formControl {
        margin-top: 8px;
      }
      .Mui-focused {
        background: #fff;
        .MuiInputLabel-root {
          z-index: 9;
        }
      }
      .MuiNativeSelect-select {
        padding-left: 14px;
        option {
          padding-left: 14px;
          text-transform: capitalize;
        }
      }
      .MuiInput-underline {
        &.Mui-focused {
          &:after {
            border-bottom: 0px;
          }
        }
        &:before {
          width: 60%;
          left: 5%;
          bottom: 5px;
        }
        &.black {
          &:before {
            background: #000;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
        &.orange {
          &:before {
            background: #ff9900;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
        &.white {
          &:before {
            background: #ffffff;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
        &.red {
          &:before {
            background: #ff0000;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
        &.blue {
          &:before {
            background: #0000ff;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
        &.yellow {
          &:before {
            background: #ffd400;
            border: 1px solid rgba(34, 34, 34, 0.36);
          }
        }
      }
    }
  }
}
