.empty.empty-root {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  justify-content: center;
  align-items: center;

  .empty-icon {
    position: relative;
    height: 50px;
    margin-bottom: $paragraph-margin;

    justify-content: center;
    align-items: center;

    svg {
      height: 100%;
      width: auto;
    }
  }

  .empty-text {
    max-width: 60%;
    text-align: center;
  }

  &.align-top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.align-top-center {
    align-items: center;
    justify-content: flex-start;
  }

  &.align-top-right {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.align-center-left {
    align-items: flex-start;
    justify-content: center;
  }

  &.align-center-center {
    align-items: center;
    justify-content: center;
  }

  &.align-center-right {
    align-items: flex-end;
    justify-content: center;
  }

  &.align-bottom-left {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.align-bottom-center {
    align-items: center;
    justify-content: flex-end;
  }

  &.align-bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
