.layout--change-request {
  &.full-screen {
    .sidebar {
      margin-left: -332px;
    }
    .layout--workflow__content {
      margin-left: 0px;
      width: calc(100% + 20px);
      flex: 1 1 100%;
      > .paper {
        width: 100%;
        height: calc(100% - 10px);
        border-radius: $border-radius;
        border-bottom: 1px solid var(--reader-border-color);
        .change-request__split-view {
          flex: 1 1 100%;
          max-height: 100%;
          transition: 0.2s ease-in-out;
        }
        .tabs {
          transition: 0.2s ease-in-out;
          height: 0;
          max-height: 0;
        }
      }
    }
  }
}
.layout--workflow {
  .sidebar {
    min-width: $sidebar-open-width;
    max-width: $sidebar-open-width;
    padding-bottom: 0;

    .sidebar-tool {
      align-items: center;

      .MuiDivider-root {
        width: 100%;
      }

      .spinner-container {
        flex-grow: 0;
      }
    }

    .sidebar-tool-header {
      width: 80%;

      * {
        padding: 0;
      }
    }

    .sidebar-tool-body {
      width: 100%;
    }

    .container {
      padding: $card-padding 0 0;

      .button {
        min-width: 100%;
      }
    }

    .workflow-tasks {
      width: 100%;
      padding: #{$base-padding * 2};
      flex-direction: column;

      button {
        width: calc(100% - #{$button-margin * 2});
        margin: 0 $button-margin $button-margin;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    .workflow-history {
      width: 100%;

      .infos {
        font-size: 13px;
        color: $main-ui-color;

        .left {
          float: left;
          width: 50%;
        }

        .right {
          float: right;
          text-align: right;
          width: 50%;
        }
      }

      .activity {
        color: gray;
        font-size: small;
      }
    }

    & ~ .subscreen-content {
      width: calc(100% - #{$sidebar-open-width});
      margin-left: $sidebar-open-width;
    }
  }

  &__content {
    position: relative;
    padding: 10px 10px 0px 20px;
    margin-left: #{$sidebar-open-width - $container-padding};
    transition-property: all;
    transition-duration: 0.5s;
    outline: 0;

    > .paper {
      height: 100%;
      width: 100%;
      display: flex;
      max-width: 100%;
      flex-direction: column;
      border-bottom: 0;
      border-radius: $border-radius $border-radius 0 0;
    }
    &.revision-content {
      height: 100%;
      width: 100%;
    }
    .tab-container {
      border-bottom: 1px solid var(--reader-border-color);
    }
  }

  .layout {
    &--change-request,
    &--revision {
      display: flex;
      height: 100%;
      width: 100%;

      .sidebar {
        .sidebar-tool {
          .sidebar-tool-header {
            .subtitle {
              .secondary {
                &.user-has-tasks {
                  color: $warning-color;
                }

                &.completed {
                  color: $draft-color;
                }
              }
            }
          }
        }
      }
    }

    &--revision {
      .layout--workflow__content {
        > .paper {
          border-top: none;
        }
      }
    }

    &--change-request {
      .layout--workflow__content {
        flex: 1 1 100%;
        width: calc(100% - 322px);

        .MuiPaper-rounded{
            border-top: 0;
        }

        .change-request__split-view {
          flex: 1 1 50%;
          position: relative;
          max-height: 60%;

          .tabs-wrapper {
            display: flex;
          }

          .tabs-container {
            flex: 1;

            .proposal-tab {
              &.Mui-selected {
                .tab-item-label {
                  .pin-button {
                    svg {
                      width: 20px;
                    }
                  }
                }
              }
            }

            .MuiTab-wrapper:hover {
              .tab-item-label {
                .pin-button {
                  visibility: visible;
                }
              }
            }

            .tab-item-label {
              display: flex;
              align-items: center;
              font-weight: bold;
              color: $text-color-light;

              .open-doc, .pin-button {
                svg {
                  width: 20px;
                }
                position: absolute;
                flex-shrink: 0;
                aspect-ratio: 1/1;
                min-width: 20px;
                min-height: 20px;
              }

              .open-doc {
                color: $blue-color-all-themes;
                margin-left: 8px;
                left: 0;
                padding: 8px;
              }

              .pin-button {
                &.pinned {
                  visibility: visible;
                }
                visibility: hidden;
                margin-right: 8px;
                right: 0;
                padding: 8px 8px 0 8px;
              }
            }

            &.draft-tabs {
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              border-left: 1px solid var(--reader-border-color);
            }

            &.left-tabs {
              border-top-right-radius: 3px;
              border-top-left-radius: 3px;
              border-right: 1px solid var(--reader-border-color);
            }
          }

          .module-version-item {
            position: relative;
            overflow-y: hidden;

            .module-version-content {
              height: 100%;
              overflow-y: scroll;
            }

            .yonder-button--fab {
              position: absolute;
              right: #{$container-padding * 2};
              bottom: #{$container-padding * 5};
              svg {
                fill: $white-color-all-themes;
              }
            }

            .switch-diffing {
              margin-top: auto;
              border-top: 1px solid var(--reader-border-color);
              .MuiGrid-item {
                padding: 0 !important;
              }
              padding-right: 18px;
            }

            .content-editor {
              .fr-box:not(.fr-fullscreen) {
                .fr-toolbar,
                .fr-wrapper,
                .second-toolbar {
                  border-left: none;
                  border-right: none;
                }

                .fr-toolbar {
                  border-top: none;
                }

                .fr-second-toolbar,
                .second-toolbar {
                  border-bottom: none;
                  display: none;
                }

                .fr-wrapper {
                  padding-bottom: 0;
                  border-bottom: none;
                }
              }

              &__actions {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: #{$container-padding * 4};
                display: flex;
                z-index: 1;
                border-top: $default-border;
                background-color: #ffffff;

                .yonder-button {
                  margin: 2px;
                  color: $blue-color-all-themes;
                }
              }
            }
          }
        }

        .tabs {
          flex: 1 1 50%;
          max-height: 40%;
          overflow-y: hidden;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}
