$star-icon-size: $base-padding * 2;

.outline {
  width: 100%;
  scrollbar-width: 2px;

  .outline-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .outline-item-text {
      display: flex;
      flex-direction: row;
      position: relative;

      color: $text-color-light;

      &:hover {
        color: $text-color;
      }

      span {
        display: flex;
        flex-direction: row;
        font-size: inherit;

        .icon {
          position: absolute;
          right: 0px;
          top: 2px;

          svg {
            width: $star-icon-size;
            height: $star-icon-size;
          }
        }

        .numbering {
          margin-right: 8px;
        }

        .text {
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .outline-item-toggle {
      position: relative;
      top: initial;
      right: initial;
      transform: initial;

      margin: -8px -16px -8px 0;
      padding: 0;

      button {
        right: 0;
        bottom: 0;

        &:hover {
          background-color: initial;
          color: $black-color-all-themes;
        }
      }
    }

    &:focus {
      background-color: inherit;
    }

    &.open {
      .outline-item-text {
        span {
          font-weight: bold;
          color: $text-color;
        }
      }
    }

    &.no-indicator::before {
      display: none;
    }
  }
}
