$tag-height: 32px;
$tag-margin-top: 2px;
$tag-margin-right: 2px;

.tags {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag.yonder-tag {
  margin: $tag-margin-top $tag-margin-right 0 0;
  height: $tag-height;
  font-size: 14px;
  justify-content: flex-start;

  padding: 0 12px 0 12px;

  &.active {
    background-color: $background-light-blue-color;
  }

  span[class^='MuiChip-label'] {
    // max-width: 125px;
    display: block;

    padding: 0;
    margin: 0 6px 0 6px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;

    &:last-child {
      margin-right: 0;
    }
  }

  svg[class^='MuiSvgIcon-root'] {
    margin: 0;
    font-size: 14px;
  }

  img {
    margin: 0;
    font-size: 14px;
  }
}
