#link-select-dropdown-wrapper {
  .MuiPaper-root {
    .MuiMenu-list {
      li {
        white-space: normal;
      }
    }
    @include media-breakpoint-down(sm) {
      left: 16px !important;
      max-width: calc(100% - 32px) !important;
    }
  }
}
