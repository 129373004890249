@mixin submission-status-background-colors {
  &.color-1 {
    background-color: $submission-status-color-1;
  }

  &.color-2 {
    background-color: $submission-status-color-2;
  }

  &.color-3 {
    background-color: $submission-status-color-3;
  }

  &.color-4 {
    background-color: $submission-status-color-4;
  }

  &.color-5 {
    background-color: $submission-status-color-5;
  }

  &.color-6 {
    background-color: $submission-status-color-6;
  }

  &.color-7 {
    background-color: $submission-status-color-7;
  }

  &.color-8 {
    background-color: $submission-status-color-8;
  }
}

@mixin submission-status-outline-colors {
  &.color-1 {
    border-color: $submission-status-color-1;
    color: $submission-status-color-1;
  }

  &.color-2 {
    border-color: $submission-status-color-2;
    color: $submission-status-color-2;
  }

  &.color-3 {
    border-color: $submission-status-color-3;
    color: $submission-status-color-3;
  }

  &.color-4 {
    border-color: $submission-status-color-4;
    color: $submission-status-color-4;
  }

  &.color-5 {
    border-color: $submission-status-color-5;
    color: $submission-status-color-5;
  }

  &.color-6 {
    border-color: $submission-status-color-6;
    color: $submission-status-color-6;
  }

  &.color-7 {
    border-color: $submission-status-color-7;
    color: $submission-status-color-7;
  }

  &.color-8 {
    border-color: $submission-status-color-8;
    color: $submission-status-color-8;
  }
}
