.menu {
    .container {
        display: flex;
        flex-grow: 1;
        min-width: 320px;
        max-width: 320px;
        height: 100%;
        background-color: $background-color;

        @include media-breakpoint-down("sm") {
            min-width: 80vw;
            max-width: 80vw;
        }
    }

    .menu__page-container {
        height: calc(100% - #{$navbar-height});

        .react-swipeable-view-container {
            height: 100% !important;
        }
    }

    .menu__page {
        width: 100%;
        height: 100%;
        flex-wrap: unset;

        &--role-settings {
            flex-wrap: wrap;
            flex-direction: row;

            & > * {
                width: 100%;
            }

            .filter-selector {
                $title-height: 32px;
                $actions-height: 40px;
                height: calc(100% - #{$navbar-height + $title-height + $actions-height});
                overflow-y: auto;
            }

            .actions {
                padding: $base-padding * 2;
            }
        }
    }

    .menu__navbar {
        padding: 0;
        height: $navbar-height;
        width: 100%;

        .menu-button-right {
            margin-left: -5px;
            margin-top: -5px;
        }
    }

    .menu__header {
        padding: $container-padding #{$base-padding * 2} !important;

        .user-info {
            padding-right: $container-padding;

            &__title {
                line-height: 1;
            }
        }
    }

    .menu__list-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        .arrow-right {
            justify-content: flex-end;
        }

        .info-item {
            flex-direction: column;
            align-items: flex-start;

            .MuiListItemText-root {
                margin: 0;
            }
        }
    }
}

.yonder-avatar.MuiAvatar-root {
    width: $avatar-size;
    height: $avatar-size;
    margin-bottom: #{$base-padding * 3};

    &.with-upload {
        cursor: pointer;
    }

    img {
        width: $avatar-size;
    }
}

.favorites-menu {
    ul {
        padding: 0;
        li {
            border-radius: 0;
            margin: 0;
            display: flex;
            gap: 14px;
            padding: 8px 14px;
        }
    }
}

.yonder-upload-avatar {
    .yonder-avatar {
        margin-bottom: 0;

        &__container.MuiGrid-item {
            padding-left: 0 !important;
        }
    }

    &__input {
        &-container.MuiGrid-item {
            padding-right: 0 !important;
            flex-grow: 1;
        }

        .MuiInputBase-input {
            cursor: pointer;
            color: transparent;
            text-shadow: 0 0 0 $text-color;

            &:focus {
                outline: none;
            }
        }
    }

    .spinner {
        margin: 0;
    }
}

/*Popover menu*/
#simple-menu {
    .MuiList-root {
        &:focus {
            outline: none;
        }
    }
    .font-size-menu-item {
        display: flex;
        justify-content: space-between;
    }
}
