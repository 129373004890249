@import 'content-style-froala';
@import 'content-style-xr';
@import 'froala-dark-mode';
@mixin document-content {
  .document-content {
    transform-origin: 0% 0%;
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: calc(100% - 6px);
    @include content-style-xr;
    @include content-style-froala;
  }
}
