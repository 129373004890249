@mixin content-style-xr {
  & > *[xr\:oid] {
    margin: 0;
  }

  *[xr\:oid] {
    img,
    figure {
      margin: 0;
      max-width: 100%;
    }

    tablewrapper {
      overflow: auto;
      -webkit-overflow-scrolling: auto;
    }

    p {
      margin: 0 0 $paragraph-margin;
    }

    table,
    graphic {
      &:before {
        content: none !important;
      }
    }

    xrpreviewtitle {
      display: none;
    }

    extref,
    xref {
      img {
        display: none;
      }

      a {
        text-decoration: none;
        color: $main-ui-color !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    graphic {
      $sizes: (
        '25-pct': 25%,
        '33-pct': 33%,
        '50-pct': 50%,
        '66-pct': 66%,
        '75-pct': 75%,
        '100-pct': 100%,
      );
      @each $size, $width in $sizes {
        &[appearance='#{$size}'] {
          width: $width;
        }
      }

      $aligns: (
        'left': 0 auto 0 0,
        'center': 0 auto,
        'right': 0 0 0 auto,
      );
      @each $align, $margin in $aligns {
        &[align='#{$align}'] {
          margin: $margin;
        }
      }

      &[border='yes'] {
        img {
          border: $default-border;
        }
      }
    }
  }
}
