.change-request {
  &--button {
    position: fixed;
    z-index: $zindex-toast;
    right: #{$container-padding * 4};
    bottom: #{$container-padding * 2};

    &.hidden {
      display: none;
    }
  }

  &--form {
    .MuiDialog-container {
      .MuiDialog-paper {
        height: 75%;

        form {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: hidden;
        }

        .MuiDialogTitle-root {
          .title {
            padding: 0 !important;
          }

          .icon {
            padding-left: 0 !important;

            svg {
              height: 100%;
              width: 35px;
            }
          }
        }

        .MuiDialogContent-root {
          padding: 0;

          & > div {
            height: 100%;

            .react-swipeable-view-container {
              height: 100% !important;

              & > div {
                height: 100%;

                .change-request-highlight-selector {
                  overflow: visible;
                }

                .module-version-highlighter,
                .module-version-editor {
                  max-height: initial;
                  height: 100%;
                }

                &:nth-child(2),
                .module-version-highlighter {
                  padding: $base-padding #{$base-padding * 3};
                }

                .content-editor {
                  .fr-toolbar {
                    border: none;
                  }

                  .fr-wrapper {
                    height: calc(100% - #{$froala-editor-action-bar-height});
                    border: none;
                  }

                  .second-toolbar {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .MuiDialogActions-root {
          .actions {
            button {
              margin-left: $base-padding;
            }
          }
        }
      }
    }
    .warning-message-wrapper {
      width: 100%;
      padding: 0px 24px;
      &--info {
        padding: 14px;
        display: flex;
        align-items: center;
        background-color: $warning-color-change-request;
        svg {
          width: 2rem;
          height: 2rem;
          fill: $black-color-all-themes;
          path {
            &:first-child {
              fill: transparent;
            }
          }
          @include media-breakpoint-down('sm') {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
        p {
          margin: 0px 0px 0px 16px;
          font-size: 0.875rem;
          font-weight: bold;
          color: $black-color-all-themes;
        }
      }
    }
  }
}
