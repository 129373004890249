.search-history {
  margin: $navbar-height 0 0 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: $zindex-toolbar;
  min-height: calc(100% - #{$navbar-height});
  display: flex;
  justify-content: center;

  position: absolute;
  height: 100%;
  width: calc(100% - 32px);

  &__list {
    position: static;
    width: 80%;
  }
}
