.modal-input {
  .modal-input-title {
    display: flex;
    justify-content: space-between;
    padding: 1em;    
  }

  .modal-input-content.MuiDialogContent-root {
    min-height: auto;
    padding: 1em;
  }

  .modal-input-actions.MuiDialogActions-root {
    justify-content: flex-end;
    border: 0;
    padding: 0 1em 1em;
    min-height: 4em;
  }
}
