.layout {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .container {
    padding: $container-padding;
    position: relative;
    height: 100%;
    padding-top: calc(64px + env(safe-area-inset-top));
    width: 100%;
    margin: 0;
    position: relative;
    @-moz-document url-prefix() {
      padding-top: 64px;
    }

    &.transfer-highlights {
      flex-wrap: nowrap;
    }
  }
}

.maintenance-message-wrapper-reader {
  z-index: 1011;
  height: auto;
  width: 100%;
  background-color: $invalid-color;
  padding: 8px 16px;
  text-align: center;
  p{
    margin: 0px;
    font-weight: 600;
    color: $dark-gray-color-all-themes;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  }
}
