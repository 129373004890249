.dark {
    .acknowledge-footer {
        background-color: $acknowledge-footer-bg-dark !important;
    }
    .task-or {
        color: black;
    }
}
.my-tasks-view {

    .no-tasks-in-open {
        padding: 1rem;
        h6 {
            color: $reader-default-color;
        }
    }

    .change-info-details {
        border: 1px solid $blue-color-all-themes;
        padding: 14px 38px;
    }

    .truncated {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .paper {
        overflow: hidden;
        .split-view {
            overflow-y: auto;
            flex-grow: 1;
            flex-shrink: 0;
            height: auto;
        }
    }

    .module-diffing-paper {
        margin: 0;
    }

    .acknowledge-footer {
        background-color: #bec6d1;
        padding: 6px;
        display: flex;
        justify-content: space-around;
        justify-self: flex-end;
        align-items: center;
        flex-shrink: 1;
        margin-right: 20px;
        margin-left: 20px;

        .task-action {
            justify-content: center;
            display: flex;
            max-width: 12rem;
            justify-self: center;
            padding: 10px;
            border-radius: 5px;
            flex-grow: 1;
        }

        .acknowledge-button {
            background-color: #22477e;
            border: 1px solid #22477e;
            color: $white-color-all-themes;
            cursor: pointer;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        }
        .task-or {
            flex-shrink: 1;
            padding: 10px;
            display: flex;
            justify-content: center;
        }

        .done-button {
            background-color: #505c6f;
            border: 1px solid #505c6f;
            color: $white-color-all-themes;
            cursor: pointer;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        }

        .next-task-button {
            background-color: #fff;
            border: 1px solid #fff;
            color: #363636;
            cursor: pointer;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
        }
    }
}

.invalid-version-padding {
    padding-bottom: $statusbar-height;
}
