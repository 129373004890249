.toast.toast-root {
  width: 100%;
  padding: 0 $base-padding;

  left: initial;
  bottom: $base-padding;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.sticky {
    position: sticky;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.dark {
    .MuiPaper-root {
      background-color: rgb(50, 50, 50);
      color: white !important;

      svg {
        color: white;
      }
    }
  }
}
