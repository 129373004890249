.form-navigation {
  &__question {
    &--required {
      &::after {
        content: '*';
        color: $error-color;
        margin-left: 4px;
      }
    }
  }
}

.form-last-submissions-list {
  &__date {
    font-size: 14px;
    color: $text-color-light;
  }
  &__empty {
    padding: $button-margin;
  }
}

.submission-status {
  .submission-badge {
    margin-left: 48px;
    padding: 10px 48px 8px 48px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    &.draft {
      padding: 4px 32px;
      border-color: $invalid-color;
      color: $invalid-color;
    }
    &.submitted {
      padding: 4px 32px;
      border-color: $blue-color-all-themes;
      color: $blue-color-all-themes;
    }
    .submission-date {
      margin-top: 0;
      margin-bottom: 4px;
      line-height: 0.5;
    }
    @include submission-status-outline-colors;
  }
}


.form-submission {
  height: calc(100% + #{$container-padding * 2});
  margin: -#{$container-padding};
  overflow-y: auto;
  justify-content: flex-start;
  min-height: 100%;
  width: calc(100% + #{$container-padding * 2});
  transition-property: all;
  transition-duration: 0.5s;
  padding: #{$base-padding * 2};

  .MuiInputLabel-asterisk {
    display: none;
  }

  &__action-buttons {
    display: inline-flex;
    gap: #{$base-padding * 1.5};

    .warning {
      margin: 8px 0;
    }

    .submit {
      background-color: $blue-color-all-themes;
      color: $white-color-all-themes;
      &:hover {
        color: $blue-color-all-themes;
        transition-property: all;
        transition-duration: 0.5s;
      }
      &[disabled] {
        background-color: $light-gray-color-all-themes;
        color: $text-color-light;
      }
    }
  }

  &__header {
    .title {
      position: relative;
      &::after {
        bottom: 0;
        color: $error-color;
        content: '*';
        position: absolute;
        right: #{$container-padding * -2};
        transform: translate(-100%, 0);
      }
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }

  .summary-field {
    margin-bottom: #{$container-padding};
    &__icon-button {
      &--hidden {
        display: none;
      }
    }
  }

  .summary-field input {
      &[disabled] {
        color: $text-color;
      }
  }

  @include survey-js-library-overrides;

  @include media-breakpoint-up('sm') {
    margin-left: #{$sidebar-width - $container-padding};

    &.tools-open {
      margin-left: #{$sidebar-open-width - $container-padding};
    }
  }
}

.manageable-submission {
  &__header {

    .details {
      margin: 0;
    }

    &__status-buttons {
      .MuiButton-contained {
        padding-top: 5px;
        padding-bottom: 3px;
      }

      display: flex;
      justify-content: flex-end;
      gap: #{$base-padding * 1.5};
      margin-bottom: #{$base-padding * 2};
      margin-left: auto;
    }
  }
}
