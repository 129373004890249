.search {
  height: calc(100% - 54px);
}
.navigation-search-bar {
  .navbar {
    header {
      background-color: $background-color;
    }

    .left-action-buttons,
    .right-action-buttons {
      flex-grow: 0;
      min-width: $container-icon-min-width;
      svg {
        fill: $reader-default-color;
      }
    }

    .center {
      flex-grow: 1;
    }
  }
}
