.split-view {
  display: flex;
  height: 100%;
  &.tab-view {
    height: calc(100% - 50px);
  }

  .split-view-item {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
    background-color: $reader-default-color-invert;
  }
}

.split-view {
  .split-view-item {
    .module-version-header {
      .title {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow-x: hidden;
      }

      &.orange{
        background-color: $invalid-color;
      }

      &.blue{
        background-color: $draft-color;
      }
    }

    .module-version-item {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100% - #{$module-version-header-height});
      padding: 0;

      .module-version-title {
        padding: #{2 * $container-padding} #{2 * $container-padding} 0;

        & ~ .module-version-content {
          padding-top: 0;
          padding-bottom: 20px;
          height: auto;
        }
      }

      .module-version-content {
        @include document-content;
        height: auto;

        &.empty {
          padding: $base-padding !important;

          align-items: center;
          justify-content: center;
        }
      }

      &.temp-revision {
        background-color: $temporary-revision-color;
      }

      &.no-selection {
        -webkit-user-select: none;
        user-select: none;
      }
    }

    &-info-box {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: #{2 * $container-padding} #{2 * $container-padding};
      background-color: $blue-color-all-themes;

      span {
        margin-left: $container-padding;
      }
    }
  }
}
