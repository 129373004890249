.navbar {
  flex-grow: 1;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  z-index: 99;

  &--invalid {
    .MuiAppBar-root {
      background-color: $invalid-color;
    }
  }

  .left-action-buttons {
    @extend %navbar-buttons;
    @extend %navbar-buttons-positioning-left;
    .doc-title {
      font-size: 16px;
      color: $black-color-all-themes;
    }
  }

  .center {
    display: flex;
    flex-shrink: 0;
    .document-version-chooser {
      display: flex;
      gap: 0.5em;
      align-items: center;
    }
  }

  .right-action-buttons {
    @extend %navbar-buttons;
    @extend %navbar-buttons-positioning-right;

    justify-content: flex-end;
    .menu-button-right {
      .MuiSvgIcon-root {
        fill: $highlight-text-color;
      }
    }
  }

  .grow {
    flex-grow: 1;
  }
}
