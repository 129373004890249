.bubble {
  &__container {
    position: relative;
    display: flex;
    align-items: center;

    .bubble {
      position: absolute;
      top: 0;
      right: calc(($bubble-size / 2) * -1);
      width: $bubble-size;
      height: $bubble-size;
      background-color: $bubble-color;
      border-radius: $bubble-radius;

      &--yellow {
        background-color: yellow;
      }
    }
  }
}
