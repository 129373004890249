.statusbar {
  .MuiToolbar-root {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-statusbar;
    height: $statusbar-height;
    min-height: $statusbar-height !important;
    background-color: $background-color;
    justify-content: center;
  }

  &--blue {
    .MuiToolbar-root {
      background-color: $draft-color;
    }
  }

  &--orange {
    .MuiToolbar-root {
      background-color: $invalid-color;
    }
  }
}
