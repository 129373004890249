.drop-down-selector {
    .tags-selector-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        .no-tags-assigned {
            text-align: left;
            padding: 1em;
            display: block;
        }
        .filter-tags-chips {
            padding: 0.5em;
            > div {
                margin-right: 0.3em;
                margin-bottom: 0.5em;
                .MuiSvgIcon-root {
                    margin-left: 0.3em;
                }
                .MuiChip-label {
                    padding-left: 0.1em;
                    padding-right: 0.1em;
                }
            }
            .clear-tags {
                display: block;
                margin: 0.5em 0;
                cursor: pointer;
            }
        }
        .tags-selector {
            border: 1px solid $border-color;
            width: 100%;
            height: 100%;
            overflow: auto;
            > div {
                >.collapse {
                    padding-left: 0;
                }
            }
            .MuiList-padding {
                padding: 0;
            }
            .MuiListItemIcon-root {
                min-width: 32px;
                margin-left: 0.5em;
            }
            .collapse {
                padding-left: 16px;
            }
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
            .list-item {
                &:hover {
                    cursor: pointer;
                }
            }
            li {
                padding-left: 0;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                .MuiTypography-root {
                    font-size: 0.875rem;
                }
                &.collapsible-list-item {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            
        }
    }
}
