.dropdown-button-wrapper {
  .dropdown-button {
    color: $reader-default-color;
    border: 0;
    padding: 0.375rem;

    &.Mui-disabled {
      cursor: not-allowed;
      pointer-events: all;
    }

    &:hover {
      background-color: $border-color;
    }
  }

  .drop-down-button-content {
    display: flex;
    align-items: center;
    font-size: 12px;

    .drop-down-button-icon {
      display: flex;
      align-items: center;

      svg {
        fill: $reader-default-color;
      }
    }
  }

  .drop-down-selector {
    width: 22em;
    position: absolute;
    z-index: 10;
    height: 100%;
    max-height: 60vh;
    overflow: hidden;

    .all-items-title,
    .empty-list-title,
    .selected-items-title {
      padding-left: 0.5em;
    }

    .all-items-title p {
      margin-bottom: 0px;
    }

    .filter-checkbox-item {
      padding-left: 0.2em;
    }

    .search-bar {
      margin: 1em 0.5em;
      background-color: $reader-default-color-invert;
    }
  }
}

.active-dropdown {
  .dropdown-button-wrapper {
    .dropdown-button {
      background-color: $blue-color-all-themes;
      color: $white-color-all-themes;

      .drop-down-button-icon {
        svg {
          fill: $white-color-all-themes;
        }
      }
    }

    .drop-down-selector {
      .item-selector,
      .tags-selector-wrapper {
        border-color: $blue-color-all-themes;
      }
    }
  }
}

.dark {
  .MuiButton-outlinedPrimary {
    border-color: white;
  }

  .dropdown-button-wrapper {
    .dropdown-button {
      border-color: transparent;
      color: $blue-color-all-themes;
      .drop-down-button-icon {
        svg {
          fill: $blue-color-all-themes;
        }
      }
    }
  }

  .active-dropdown {
    .dropdown-button-wrapper {
      .dropdown-button {
        background-color: $blue-color-all-themes;
        color: $white-color-all-themes;

        svg {
          fill: $white-color-all-themes;
        }
      }
    }
  }

  .dropdown-button {
    &.apply-button {
      &.Mui-disabled {
        cursor: not-allowed;
        pointer-events: all;
      }
    }
  }
}
