.version-chooser {
  .MuiSelect-root {
    padding-top: 0; // Center element
    padding-bottom: 0; // Center element
    color: $black-color-all-themes;
    
    @include media-breakpoint-down('xs') {
      max-width: 200px;
    }   
    @include media-breakpoint-up('xs') {
      max-width: 500px;
    }   

    @include media-breakpoint-up('md') {
      max-width: 800px;
    }  
    @include media-breakpoint-up('lg') {
      max-width: 1100px;
    }


    &:active {
      background-color: inherit;
    }

    &:focus {
      background-color: inherit;
    }
  }

  &.Mui-disabled {
    .MuiSelect-icon {
      display: none;
    }
  }

  .MuiSelect-icon {
    color: $black-color-all-themes;
  }

  &::before {
    display: none !important; // Hide bottom border
  }

  &::after {
    display: none !important; // Hide bottom border
  }
}
