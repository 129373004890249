.icon {
  &--large {
    width: 40px;
    height: 50px;
  }

  &--medium {
    width: 20px;
    height: 30px;
  }

  &--small {
    width: 10px;
    height: 20px;
  }

  &.icon-importance {
    &.major {
      color: red;
    }

    &.minor {
      color: gold;
    }

    &.editorial {
      color: forestgreen;
    }
  }
}

.yonder {
  .fil0,
  .fil1,
  .fil2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }

  .fil0 {
    fill: #6b6b6b;
  }

  .fil1 {
    fill: #e5f3fd;
  }

  .fil2 {
    fill: #5ca8f4;
  }
}

.labeled-icon {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;

  .labeled-icon--label {
    visibility: visible;
    opacity: 1;

    transition: visibility ease-out 250ms, opacity ease-out 250ms;

    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}


.diffing-icon {
  svg {
    * {
      fill: $reader-default-color;
      stroke: $reader-default-color;
    }
  }
  &:hover {
    svg {
      * {
        fill: $icon-not-active-state;
        stroke: $icon-not-active-state;
      }
    } 
  }
  &.active {
    svg {
      * {
        fill: #FF4600;
        stroke: #FF4600;
      }
    }
    &:hover {
      svg {
        * {
          fill: $reader-default-color;
          stroke: $reader-default-color;
        }
      } 
    }
  }
}