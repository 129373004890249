.tabs {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  &.no-scroll {
    span[class*='MuiTab-wrapper'] {
      line-height: 1;
      display: block;
      overflow: hidden;
    }

    &.ellipsis {
      .MuiTab-wrapper {
        text-overflow: ellipsis;
      }
    }
  }

  &.multi_tabs {
    .tabs-header {
      .MuiTab-root {
        font-size: 0.74rem;
      }
    }
  }

  .tabs-header {
    position: relative;

    height: $tabs-tab-height;
    min-height: $tabs-tab-height;

    .MuiTab-wrapper:hover {
      .tab-item-label {
        .pin-button {
          visibility: visible;
        }
      }
    }
    .tab-item-label {
      display: flex;
      align-items: center;
      .pin-button {
        &.pinned {
          visibility: visible;
        }
        svg {
          width: 20px;
        }
        visibility: hidden;
        position: absolute;
        margin-right: 16px;
        flex-shrink: 0;
        aspect-ratio: 1/1;
        right: 0;
        padding: 8px 8px 0 8px;
        min-width: 20px;
        min-height: 20px;
      }
    }

    .MuiTabs-scroller {
      max-height: $tabs-tab-height;

      .MuiTabs-flexContainer {
        height: 100%;
      }
    }

    .MuiTab-root {
      max-height: $tabs-tab-height;
      flex-grow: 1;
      min-width: auto;
      max-width: initial;
      height: $tabs-header-height;
      padding: 6px;
      //Fix fonts for dashboard tabs
      @media screen and (min-width: 1440px) and (max-width: 1750px) {
        font-size: 0.7rem;
      }
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        font-size: 0.65rem;
      }
      @media screen and (min-width: 1100px) and (max-width: 1279px) {
        font-size: 0.75rem;
      }
      @media screen and (min-width: 960px) and (max-width: 1099px) {
        font-size: 0.65rem;
      }
      @include media-breakpoint-down('sm') {
        font-size: 0.75rem;
      }
    }
  }

  .react-swipeable-view-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: calc(100% - #{$tabs-tab-height});

    &.drag-and-drop {
      .react-swipeable-view-container {
        height: auto !important;
      }
    }

    .react-swipeable-view-container {
      height: 100% !important;
      flex-grow: 1;

      .slide {
        height: 100%;
      }

      .tab-container {
        height: 100%;
        width: 100%;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}
