$hot-icon-size: $base-padding * 2;

.yonder-infinity-list-container {
  height: 100%;

  & > div {
    display: flex;
    min-height: 100%;
    height: 100%;
  }

  .yonder-infinity-list {
    width: 100%;
    min-height: 100%;

    .empty-list {
      width: 100%;
      margin-top: 20px;
    }

    .no-items {
      padding: $base-padding #{$base-padding * 2};
    }

    .spinner-container {
      display: initial;
    }

    .MuiDivider-root {
      margin-left: $base-padding * 2;
      width: calc(100% - #{$base-padding * 2});
    }
  }
}

.card-list {
  .no-items {
    padding: $base-padding #{$base-padding * 2};
  }
}

.yonder-list-item {
  &.favorite-group-list-item {
    padding-left: 0;
    padding-right: 0;

    .yonder-list-item-icon {
      justify-content: center;
    }
  }

  &.document-card-list-item {
    .show-favorite-at-hover {
      visibility: hidden;
    }
    .favorite {
      visibility: visible;
    }
    padding: 8px 16px;

    .yonder-list-item-icon {
      display: inline-flex;
      min-width: 54px;
    }
  }

  &.document-card-list-item + hr,
  &.favorite-group-list-item + hr {
    margin-left: 4em;
  }

  &.current-folder {
    background-color: rgba(110, 167, 237, 0.2);
  }
  &.opened-folder {
    background-color: rgba(245, 245, 245, 1);
  }
  &:hover {
    .show-favorite-at-hover {
      visibility: visible;
    }
  }
  &.new-favorite {
    padding-left: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.dark {
  .yonder-list-item {
    &.opened-folder {
      background-color: rgba(110, 167, 237, 0.1);
    }
  }
}

.yonder-list {
  .yonder-favorite-list-item {
    padding-left: 0;
    .yonder-list-item-icon {
      min-width: 1em;
      visibility: hidden;
      > span {
        padding: 0;
      }
      .MuiIconButton-label {
        padding: 6px 6px;
      }
      svg {
        width: 0.625em;
        height: 0.625em;
      }
    }
    &:hover {
      .yonder-list-item-icon {
        visibility: visible;
      }
    }

    .list-item-more {
      right: 4px;
    }
  }
  .new-favorite {
    padding-left: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  &.checkbox-active-hover-only {
    .yonder-favorite-list-item {
      .yonder-list-item-icon {
        visibility: visible;
      }
    }
  }
  &.checkbox-always-active {
    .yonder-favorite-list-item {
      .yonder-list-item-icon {
        visibility: visible;
      }
    }
  }
  .favorite-group-list-item {
    min-height: 4em;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &.active {
      background-color: $active-favorite-group-list-item-color;
    }
    &.active + .favorite-group-list-item-divider {
      margin-left: 0;
      width: 100%;
    }
    .yonder-list-item-icon {
      min-width: 1.625em;
    }

    .favorite-groups-primary {
      display: flex;
      align-items: center;

      .favorite-groups-primary-icon {
        display: flex;
        margin-right: 0.5em;
        svg {
          width: 1.5em;
          height: 1.25em;
        }
      }
    }

    .list-item-more {
      right: 4px;
    }
  }

  .favorite-items-in-group {
    .yonder-list-item {
      padding-left: 2.1em;
    }
  }

  .no-items {
    padding: $base-padding #{$base-padding * 2};
  }

  .MuiDivider-root {
    margin-left: $base-padding * 2;
    width: calc(100% - #{$base-padding * 2});
  }
}

.MuiListItem-button:hover.yonder-list-item-archived {
  background-color: inherit;
  cursor: inherit;
}

.yonder-list-item.MuiListItem-root {
  &.more {
    .list-item-secondary {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  &.with-action {
    .yonder-list-item-content {
      grid-template-areas:
        "tertiary action"
        "primary action"
        "secondary action";
    }
  }

  &.with-more {
    .yonder-list-item-content {
      grid-template-areas:
        "tertiary meta"
        "primary primary"
        "secondary more";
    }
  }

  &.with-action.with-more {
    .yonder-list-item-content {
      grid-template-areas:
        "tertiary meta"
        "primary action"
        "secondary more";
    }
  }

  &.with-action.with-meta {
    .yonder-list-item-content {
      grid-template-areas:
        "tertiary meta"
        "primary primary"
        "secondary more";
    }
  }

  &.focused {
    background-color: rgb(225, 225, 225);
  }

  &.item__task {
    &--unread {
      .list-item-primary {
        font-weight: bold;
      }
    }

    &--state {
      &-blue {
        .list-item-meta {
          color: cornflowerblue;
        }
      }

      &-orange {
        .list-item-meta {
          color: orange;
        }
      }

      &-red {
        .list-item-meta {
          color: red;
        }
      }
    }

    &.active {
      background-color: $task-list-item-bg;
    }

    .delete-button {
      width: 2em;
      height: 2em;
      &:hover {
        background-color: $icon-hover-bg-color;
        svg {
          color: $reader-default-color;
        }
      }
    }
  }

  &.item__change {
    .yonder-list-item-icon {
      .hot-button.disabled {
        position: absolute;
        left: -2px;
        top: 4px;
        svg {
          width: $hot-icon-size;
          height: $hot-icon-size;
        }
      }
    }
  }

  &.archived-favorite-item {
    cursor: default;
    .list-item-more {
      cursor: pointer;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
      }
      &:hover {
        > div {
          background-color: $icon-hover-bg-color;
        }
      }
    }
    &:hover {
      background-color: transparent;
    }
  }

  .yonder-list-item-content {
    display: grid;
    grid-template-areas:
      "tertiary meta"
      "primary primary"
      "secondary secondary";

    grid-template-columns: 1fr auto;
    grid-column-gap: $base-padding;
    &.move-modal {
      grid-column-gap: 0;
    }

    align-items: stretch;
    word-break: break-word;
    flex-grow: 1;
    //padding-left: $base-padding;

    .list-item-primary {
      grid-area: primary;

      display: flex;
      flex-grow: 1;
    }

    .list-item-secondary {
      grid-area: secondary;

      display: flex;
      flex-grow: 1;

      word-break: break-word;
    }

    .list-item-tertiary {
      grid-area: tertiary;

      flex-grow: 1;

      overflow-x: hidden;
      text-overflow: ellipsis;
      display: block;
      &.archived-title {
        color: $warning-color;
      }
    }

    .list-item-meta {
      grid-area: meta;

      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .released {
      color: black;
    }

    .user-has-tasks {
      color: orange;
    }

    .list-item-action {
      grid-area: action;

      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;
    }

    .list-item-more {
      grid-area: more;

      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      button {
        min-height: auto;
        height: auto;
      }
    }
  }

  .wf-notification-meta-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    .list-item-meta {
      width: 100%;
    }
  }

  &.workflow-action {
    .list-item-action {
      display: none;
      align-items: center;
    }
    &:hover {
      .list-item-action {
        display: flex;
      }
    }
  }
}

.yonder-info-list {
  position: relative;

  min-height: 100%;
  width: 100%;
  height: 100%;

  padding: 0;

  overflow-y: auto;

  .MuiGrid-container {
    height: 100%;
  }

  .info-variable-name {
    padding-right: $base-padding;
    max-width: 33%;
    min-width: 33%;

    word-wrap: break-word;
    text-overflow: ellipsis;

    span {
      font-weight: bold;
    }
  }

  .info-variable-divider {
    margin-left: $base-padding * 2;
  }
}

.dark {
  .yonder-list-item.MuiListItem-root {
    .yonder-list-item-content {
      .released {
        color: #ffffff;
      }
    }
  }
  .favorite-icon {
    path,
    circle {
      stroke: #ffffff;
      fill: #333333;
    }
  }
  .favorite-group-list-item {
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  .filled-favorite-icon {
    .path1 {
      fill: #333;
      stroke: #fff;
    }
    .path2 {
      fill: #fff;
      stroke: #fff;
    }
  }
  .empty-favorite-group-icon {
    path {
      fill: transparent;
      stroke: #fff;
      &:last-child {
        fill: #333;
        stroke: #fff;
      }
    }
  }
}

.dark {
  .item__task {
    &.active {
      .list-item-tertiary {
        color: $task-list-item-tertiary-color-dark;
      }
      .list-item-primary {
        color: black;
      }

      .list-item-secondary {
        color: $task-list-item-secondary-color-dark;
      }
      background-color: $task-list-item-bg-dark !important;
    }
  }
}
