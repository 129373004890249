.version-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 0px 20px 20px;
  p {
    font-size: 11px;
    margin-bottom: 0px;
  }
}
