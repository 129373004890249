// Core Import
@import '~@yonder-mind/ui-core/src/styles/app';

// UI-Web
@import '0-mixins/0-mixins-dir';
@import '1-base/1-base-dir';
@import '2-components/2-components-dir';
@import '3-containers/3-containers-dir';
@import "4-storybooklet/storybooklet";

// Edge-Fixes

@supports (-ms-ime-align: auto) {
    .MuiTabs-scrollable {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }
    .drawer {
        &.hidden {
            left: calc(-100vw + #{$sidebar-width}) !important;
        }
    }
}
