@mixin title {
  &.h1 {
    font-size: 140%;
    font-weight: bold;
  }

  &.h2 {
    font-size: 130%;
    font-weight: bold;
    color: var(--reader-text-color-heading);
  }

  &.h3 {
    font-size: 120%;
    font-weight: bold;
    color: var(--reader-text-color-heading);
  }

  &.h4 {
    font-size: 115%;
    font-weight: bold;
    color: var(--reader-text-color-heading);
  }

  &.h5 {
    font-size: 110%;
    font-weight: bold;
    color: var(--reader-text-color-heading);
  }

  &.h6 {
    font-size: 105%;
    font-weight: bold;
    color: var(--reader-text-color-heading);
  }
}

.font {
  &.title {
    @include title;
  }
}
