.layout--file-drop .container {
  flex-direction: column;
  flex-wrap: nowrap;
  .tab-switcher {
    margin-bottom: 1em;
    border-top: 0;
    width: 100%;
  }
  .tab-panel {
    flex-grow: 1;
    width: 100%;
    padding: 0;
  }
}

.file-drop-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .filedrop-spinner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .file-drop-header {
    display: flex;
    align-items: center;
  }
  .file-drop-cancel-button{
    margin-right: 1em;
  }
  .file-drop-body {
    display: flex;
    justify-content: flex-start;
    gap: 1em;
    height: 100%;
    .file-drop-table-card {
      justify-content: center;
      padding: 0 1em;
      .file-drop-header-buttons {
        display: flex;
        width: 100%;
        margin-top: 1em;
        .file-drop-header {
          margin-right: 1em;
        }
      }
      .file-drop-no-files-selected {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .file-drop-no-files-selected-icon {
          color: grey;
          font-size: 10em;
        }
      }

    .file-drop-table-page {
        width: 100%;

        .file-drop-search-bar {
          width: 50%;
        }

        & .MuiTableCell-sizeSmall {
          padding: 0;
        }

        .file-type-icon {
          padding-right: 1em;
          color: $text-color-light;
          svg {
            vertical-align: middle;
          }
        }

        &.library-table {
          .file-type-icon {
            svg {
              margin-left: 0.5em;
            }
          }
        }

        .table-head-active {
          font-weight: 900;
        }

        .file-drop-table-container {
          max-height: 75vh;

          .checkbox-column-header {
            width: 3em;
          }

          .type-column-header {
            width: 4em;
          }

          .name-column-header {
            width: 25em;
          }
          .start-date-column-header {
            width: 10em;
          }
          .end-date-column-header {
            width: 10em;
          }
          .upload-date-column-header {
            width: 10em;
          }

          .file-drop-table-row {
            &:hover {
              background: $background-light-blue-color;
            }
            &:not(:hover) .kebab-button{
              opacity: 0;
            }

            .kebab-menu-cell {
              text-align: right;
            }

            &.Mui-selected {
              background: $blue-color-faded;
              position: relative;
              > td {
                &:before {
                  content: "";
                  width: 0.3em;
                  height: 100%;
                  background-color: $blue-color-all-themes;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.file-drop-enter-layer {
  position: fixed;
  background-color: rgba(131, 131, 131, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  &__content {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper {
      width: 20em;
      height: 20em;
      background-color: #D8D8D8;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down('md') {
        width: 16em;
        height: 16em;
      }
      @include media-breakpoint-down('sm') {
        width: 12em;
        height: 12em;
      }
      .file-drop-no-files-selected-icon-outlined {
        font-size: 14em;
        @include media-breakpoint-down('md') {
          font-size: 12em;
        }
        @include media-breakpoint-down('sm') {
          font-size: 8em;
        }
      }
      .drop-icon-title {
        margin: 0px;
        text-align: center;
        font-size: 2em;
        font-weight: 600;
        @include media-breakpoint-down('sm') {
          font-size: 1.5em;
        }
      }
    }
  }
}


.dark {
  .file-drop-header-buttons {
    .yonder-button {
      border-color: $blue-color-all-themes;
      color: $blue-color-all-themes;
    }
  }
  .file-drop-table-checkbox {
    color: $light-gray-color-all-themes;
  }
  .file-drop-table-row {
    &:hover {
      background: $dark-gray-color-all-themes;
    }
    &.Mui-selected {
      background: #0b4e91;
    }
  }
  .file-drop-enter-layer__content__wrapper {
    svg {
      fill: $black-color-all-themes;
    }
    .drop-icon-title {
      color: $black-color-all-themes;
    }
  }
}

.memo-dialog{
  .memo-dialog-paper {
    height: 100%;
    .memo-dialog-title {
      padding: 1em;
      .menu-button-right {
        position: absolute;
        top: 1em;
        right: 1em;
        padding: 0px;
      }
    }
    .memo-dialog-content {
      flex-wrap: wrap;
      overflow: hidden;
      padding: 1em;
      .memo-title-wrapper, .memo-editor-wrapper {
        display: flex;
        margin-bottom: 1em;
        width: 100%;
        flex-basis: 100%;
      }
      .memo-editor-wrapper {
        height: calc(100% - 6em);
        > div {
          flex: 1;
          .fr-box {
            height: calc(100% - 4em);
            .fr-wrapper {
              height: 100%;
              .fr-view {
                height: 100%;
                overflow: auto;
              }
            }
          }
        }
      }

    }
    .memo-dialog-actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.memo-froala-content, .memo-content {
  height: 100%;
  @include content-style-xr;
  @include content-style-froala;
}



.layout--memo {
  .memo-content {
    width: 100%;
    img {
      &:hover {
        cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z'/%3E%3C/svg%3E")
            15 15,
          pointer;
      }
    }
  }
}
