/*!
 * froala_editor v3.2.2 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2020 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.dark-theme.fr-box.fr-basic {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-box.fr-basic .fr-element {
  font-family: sans-serif;
  color: #414141;
  font-size: 14px;
  line-height: 1.6;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: auto;
  min-height: 60px;
  text-align: left;
}
.dark-theme .fr-element {
  -webkit-user-select: auto;
}
.dark-theme .fr-element a {
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}
.dark-theme .fr-element.fr-disabled {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme.fr-box a.fr-floating-btn {
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 40px;
  width: 40px;
  background: #353535;
  color: #0098f7;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  left: 0;
  top: 0;
  line-height: 40px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}
.dark-theme.fr-box a.fr-floating-btn svg {
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  fill: #0098f7;
}
.dark-theme.fr-box a.fr-floating-btn i,
.dark-theme.fr-box a.fr-floating-btn svg {
  font-size: 14px;
  line-height: 40px;
}
.dark-theme.fr-box a.fr-floating-btn:hover {
  background: #424242;
}
.dark-theme.fr-box a.fr-floating-btn:hover svg {
  fill: #0098f7;
}
.dark-theme.fr-box .fr-visible a.fr-floating-btn {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.dark-theme iframe.fr-iframe {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme .fr-wrapper::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}
.dark-theme .fr-wrapper .fr-placeholder {
  font-size: 14px;
  color: #aaa;
  font-family: sans-serif;
  top: 0;
  left: 0;
  right: 0;
}
.dark-theme .fr-wrapper ::-moz-selection {
  background: #b5d6fd;
  color: #000;
}
.dark-theme .fr-wrapper ::selection {
  background: #b5d6fd;
  color: #000;
}
.dark-theme.fr-box.fr-basic .fr-wrapper {
  background: #fff;
  border: 1px solid #cccccc;
  border-bottom-color: #efefef;
  top: 0;
  left: 0;
}
.dark-theme.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
}
.dark-theme.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
@media (min-width: 992px) {
  .dark-theme .fr-box.fr-document .fr-wrapper {
    padding: 30px;
    background: #efefef;
  }
  .dark-theme .fr-box.fr-document .fr-wrapper .fr-element {
    background: #fff;
    margin: auto;
    z-index: auto;
  }
  .dark-theme .fr-box.fr-document .fr-wrapper .fr-element hr {
    background: #efefef;
  }
}
.dark-theme .fr-sticky-on {
  z-index: 10;
}
.dark-theme .fr-sticky-on.fr-sticky-ios {
  left: 0;
  right: 0;
}
.dark-theme span.fr-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.dark-theme .fr-opacity-0 {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-box .fr-counter {
  padding: 10px;
  color: #999999;
  font-size: 14px;
  font-family: sans-serif;
  border-radius: 2px 0 0 0;
  -moz-border-radius: 2px 0 0 0;
  -webkit-border-radius: 2px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-box.fr-rtl .fr-counter {
  left: 0;
  right: auto;
  border-radius: 0 2px 0 0;
  -moz-border-radius: 0 2px 0 0;
  -webkit-border-radius: 0 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme textarea.fr-code {
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  background: #fff;
  color: #000;
}
.dark-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  top: 0;
  right: 0;
  background: #fff;
  color: #fff;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  padding: 8px 7px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
  font-size: 24px;
  width: 24px;
}
.dark-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #424242;
}
.dark-theme.fr-popup .fr-layer.fr-color-hex-layer {
  margin: 0;
  padding: 20px;
  padding-top: 0;
}
.dark-theme.fr-popup .fr-layer.fr-color-hex-layer .fr-input-line {
  padding: 15px 0 0;
}
.dark-theme.fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons {
  width: 38px;
  height: 40px;
  padding: 17px 0 0;
  margin: 0;
}
.dark-theme.fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons button.fr-command {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 13px;
  height: 40px;
  width: 38px;
}
.dark-theme.fr-popup .fr-color-set {
  line-height: 0;
}
.dark-theme.fr-popup .fr-color-set.fr-selected-set {
  padding: 20px;
  padding-bottom: 0;
}
.dark-theme.fr-popup .fr-color-set > span {
  width: 32px;
  height: 32px;
}
.dark-theme.fr-popup .fr-color-set > span > i,
.dark-theme.fr-popup .fr-color-set > span > svg {
  line-height: 32px;
  height: 24px;
  width: 24px;
  margin: 4px;
  font-size: 13px;
  bottom: 0;
  left: 0;
}
.dark-theme.fr-popup .fr-color-set > span > i path,
.dark-theme.fr-popup .fr-color-set > span > svg path {
  fill: #fff;
}
.dark-theme.fr-popup .fr-color-set > span .fr-selected-color {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.dark-theme.fr-popup .fr-color-set > span:hover,
.dark-theme.fr-popup .fr-color-set > span:focus {
  outline: 1px solid #fff;
}
.dark-theme.fr-rtl
  .fr-popup
  .fr-colors-tabs
  .fr-colors-tab.fr-selected-tab[data-param1='text']
  ~ [data-param1='background']::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
}
.dark-theme .fr-drag-helper {
  background: #0098f7;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  z-index: 2147483640;
}
.dark-theme .fr-dragging {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-popup .fr-emoticon {
  width: 24px;
  height: 24px;
}
.dark-theme.fr-popup .fr-emoticon img {
  height: 24px;
  width: 24px;
}
.dark-theme.fr-popup .fr-command.fr-btn.fr-tabs-unicode {
  padding: 0 0 0 14px;
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .dark-theme.fr-popup .fr-icon-container.fr-emoticon-container {
    width: 368px;
  }
}
.dark-theme.fr-popup .fr-file-upload-layer {
  border: dashed 2px gray;
  padding: 25px 0;
  margin: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme.fr-popup .fr-file-upload-layer:hover {
  background: #424242;
}
.dark-theme.fr-popup .fr-file-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
.dark-theme.fr-popup .fr-file-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme.fr-popup .fr-file-upload-layer .fr-form input {
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
}
.dark-theme.fr-popup .fr-file-progress-bar-layer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme.fr-popup .fr-file-progress-bar-layer > h3 {
  margin: 10px 0;
}
.dark-theme.fr-popup .fr-file-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
.dark-theme.fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.dark-theme.fr-popup .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.dark-theme .fr-element img {
  padding: 0 1px;
}
.dark-theme .fr-image-resizer {
  border: solid 1px #0098f7;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-image-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme .fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .dark-theme .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.dark-theme.fr-image-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme .fr-by-url-padding {
  padding: 11.5px 0;
}
.dark-theme.fr-popup .fr-upload-progress-layer {
  border-radius: 0;
  overflow-y: auto;
}
.dark-theme.fr-popup .fr-files-upload-layer {
  border: dashed 2px gray;
  margin: 20px;
}
.dark-theme.fr-popup .fr-files-upload-layer:hover {
  background: #424242;
}
.dark-theme.fr-popup .fr-files-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
.dark-theme.fr-popup .fr-files-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme.fr-popup .fr-files-upload-layer .fr-form input {
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
}
.dark-theme.fr-popup .fr-files-progress-bar-layer > h3 {
  margin: 10px 0;
}
.dark-theme.fr-popup .fr-files-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
.dark-theme.fr-popup .fr-files-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.dark-theme.fr-popup .fr-files-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.dark-theme .fr-progress-bar {
  background-color: #4caf50;
}
.dark-theme .fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme .fr-icons {
  padding: 10px 20px 0;
}
.dark-theme .half-circle {
  border-bottom: 0;
}
.dark-theme .checkbox-container input {
  opacity: 0;
  height: 0;
  width: 0;
}
.dark-theme .checkmark {
  top: 0;
  left: 0;
}
.dark-theme .fr-cloud-icon {
  margin: 0 auto;
}
.dark-theme .fr-files-checkbox {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-files-checkbox svg {
  width: 10px;
  height: 10px;
}
.dark-theme .fr-files-checkbox span {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.dark-theme .fr-files-checkbox input {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  height: 18px;
  margin: 0;
  padding: 0;
  width: 18px;
}
.dark-theme .fr-files-checkbox input:not(:checked) + span {
  border: solid 2px #999999;
}
.dark-theme .fr-files-checkbox input:not(:checked):active + span {
  background-color: #353535;
}
.dark-theme .fr-files-checkbox input:not(:checked):focus + span,
.dark-theme .fr-files-checkbox input:not(:checked):hover + span {
  border-color: #515151;
}
.dark-theme .fr-files-checkbox input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
.dark-theme .fr-files-checkbox input:checked:active + span {
  background-color: #ecf5ff;
}
.dark-theme .fr-files-checkbox input:checked:focus + span,
.dark-theme .fr-files-checkbox input:checked:hover + span {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme .fr-checkbox-disabled {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-checkbox-disabled span {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.dark-theme .fr-checkbox-disabled input {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  margin: 0;
  padding: 0;
}
.dark-theme .fr-checkbox-disabled input:not(:checked) + span {
  border: solid 2px #999999;
}
.dark-theme .fr-checkbox-disabled input:not(:checked):active + span {
  background-color: #353535;
}
.dark-theme .fr-checkbox-disabled input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
.dark-theme .fr-checkbox-disabled input:checked:active + span {
  background-color: #ecf5ff;
}
.dark-theme .fr-files-manager-tooltip .tooltiptext {
  font-weight: 100;
}
.dark-theme .fr-file-view-modal {
  left: 0;
  top: 0;
  overflow: auto;
}
.dark-theme .fr-file-view-image {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.dark-theme .fr-file-error {
  font-weight: 200;
}
.dark-theme .fr-autoplay-checkbox-label {
  margin: 0;
}
.dark-theme .checkbox-container .checkmark:after {
  border-width: 0 3px 3px 0;
}
.dark-theme .file-item-details .file-description {
  font-weight: 300;
}
.dark-theme #myprogressBar {
  background-color: #4caf50;
}
.dark-theme .progress-circle {
  padding: 0;
}
.dark-theme .fr-hovered-over-file {
  background-color: #f0f0f0;
}
.dark-theme.fr-box.fr-fullscreen {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.dark-theme.fr-box.fr-fullscreen.fr-basic.fr-top .fr-wrapper {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-box.fr-fullscreen.fr-basic.fr-bottom .fr-wrapper {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-box.fr-fullscreen .fr-toolbar {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-box.fr-fullscreen .second-toolbar {
  margin-top: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
  border: 0;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
  border-bottom: solid 1px #595959;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
  color: white;
}
.dark-theme .fr-element img {
  padding: 0 1px;
}
.dark-theme .fr-image-resizer {
  border: solid 1px #0098f7;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-image-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme .fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.dark-theme .fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .dark-theme .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .dark-theme .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.dark-theme.fr-image-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme.fr-popup .fr-image-upload-layer {
  border: dashed 2px gray;
  padding: 25px 0;
  margin: 20px;
}
.dark-theme.fr-popup .fr-image-upload-layer:hover {
  background: #424242;
}
.dark-theme.fr-popup .fr-image-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
.dark-theme.fr-popup .fr-image-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme.fr-popup .fr-image-upload-layer .fr-form input {
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
}
.dark-theme.fr-popup .fr-image-progress-bar-layer > h3 {
  margin: 10px 0;
}
.dark-theme.fr-popup .fr-image-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
.dark-theme.fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.dark-theme.fr-popup .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.dark-theme .fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-head-line::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more {
  margin-top: 10px;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more.fr-not-available {
  opacity: 0;
  width: 0;
  padding: 12px 0;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags {
  padding: 0 20px;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a {
  opacity: 0;
  padding: 6px 12px;
  margin: 8px 0 8px 8px;
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  background-color: #353535;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a:focus {
  background-color: #424242;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a:hover {
  background-color: #424242;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a:active {
  background-color: #4f4f4f;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background-color: #4f4f4f;
  color: #0098f7;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:focus {
  background-color: #424242;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:hover {
  background-color: #424242;
}
.dark-theme.fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:active {
  background-color: #4f4f4f;
}
.dark-theme.fr-show-tags .fr-modal-more svg path {
  fill: #0098f7;
}
.dark-themediv.fr-modal-body {
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
}
.dark-themediv.fr-modal-body .fr-preloader {
  margin: 50px auto;
}
.dark-themediv.fr-modal-body div.fr-image-list {
  margin: 0 20px;
  padding: 0;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
  color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
  background: #aaa;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container img {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected img {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img,
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  line-height: 40px;
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
  background: #b8312f;
  fill: #fff;
  padding: 8px;
  -webkit-transform: translateY(-50%) translateX(25%);
  -moz-transform: translateY(-50%) translateX(25%);
  -ms-transform: translateY(-50%) translateX(25%);
  -o-transform: translateY(-50%) translateX(25%);
}
.dark-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  background: #353535;
  fill: #0098f7;
  padding: 8px;
  -webkit-transform: translateY(-50%) translateX(-125%);
  -moz-transform: translateY(-50%) translateX(-125%);
  -ms-transform: translateY(-50%) translateX(-125%);
  -o-transform: translateY(-50%) translateX(-125%);
}
.dark-theme.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover img {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-desktop
  .fr-modal-wrapper
  div.fr-modal-body
  div.fr-image-list
  div.fr-image-container:hover
  .fr-delete-img,
.dark-theme.fr-desktop
  .fr-modal-wrapper
  div.fr-modal-body
  div.fr-image-list
  div.fr-image-container:hover
  .fr-insert-img {
  width: 40px;
  height: 40px;
}
.dark-theme.fr-desktop
  .fr-modal-wrapper
  div.fr-modal-body
  div.fr-image-list
  div.fr-image-container
  .fr-delete-img:hover {
  background: #bf4644;
  color: #fff;
}
.dark-theme.fr-desktop
  .fr-modal-wrapper
  div.fr-modal-body
  div.fr-image-list
  div.fr-image-container
  .fr-insert-img:hover {
  background: #424242;
}
.dark-theme .fr-line-breaker {
  border-top: 1px solid #0098f7;
}
.dark-theme .fr-line-breaker a.fr-floating-btn {
  left: calc(50% - (40px / 2));
  top: -20px;
}
.dark-theme .fr-line-breaker a.fr-floating-btn svg {
  margin: 8px;
  height: 24px;
  width: 24px;
}
.dark-theme .fr-quick-insert {
  padding-right: 10px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-quick-insert a.fr-floating-btn svg {
  margin: 8px;
  width: 24px;
  height: 24px;
}
.dark-theme .fr-quick-insert.fr-on a.fr-floating-btn svg {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
}
.dark-theme .fr-qi-helper {
  padding-left: 20px;
}
.dark-theme .fr-qi-helper a.fr-btn.fr-floating-btn {
  padding: 6px 10px 10px 10px;
  color: #fff;
  background: #353535;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
}
.dark-theme .fr-qi-helper a.fr-btn.fr-floating-btn svg {
  fill: #fff;
}
.dark-theme .fr-qi-helper a.fr-btn.fr-floating-btn.fr-size-1 {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.dark-theme.fr-popup .fr-buttons.fr-tabs .fr-special-character-category {
  padding: 10px 15px;
}
.dark-theme.fr-popup .fr-buttons.fr-tabs .fr-special-character-category span {
  font-size: 16px;
}
.dark-theme.fr-popup .fr-special-character {
  width: 24px;
  height: 24px;
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .dark-theme.fr-popup .fr-icon-container.fr-sc-container {
    width: 368px;
  }
}
.dark-theme .fr-element table td.fr-selected-cell,
.dark-theme .fr-element table th.fr-selected-cell {
  border: 1px double #0098f7;
}
.dark-theme .fr-element table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .fr-element table td,
.dark-theme .fr-element table th {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.dark-theme .fr-element .fr-no-selection table td,
.dark-theme .fr-element .fr-no-selection table th {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .fr-table-resizer div {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  border-right: 1px solid #0098f7;
}
.dark-theme .fr-no-selection {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme.fr-popup .fr-table-size {
  margin: 20px;
}
.dark-theme.fr-popup .fr-table-size .fr-select-table-size {
  line-height: 0;
}
.dark-theme.fr-popup .fr-table-size .fr-select-table-size > span {
  padding: 0 4px 4px 0;
}
.dark-theme.fr-popup .fr-table-size .fr-select-table-size > span > span {
  border: 1px solid #ddd;
}
.dark-theme.fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
  background: rgba(0, 152, 247, 0.3);
  border: solid 1px #0098f7;
}
.dark-theme.fr-popup .fr-table-size .fr-select-table-size .new-line::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}
.dark-theme.fr-popup .fr-table-colors {
  padding: 20px;
  padding-bottom: 0;
}
.dark-theme .fr-element .fr-video {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .fr-element .fr-video::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dark-theme .fr-element .fr-video > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme.fr-box .fr-video-resizer {
  border: solid 1px #0098f7;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .dark-theme.fr-box .fr-video-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .dark-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.dark-theme.fr-popup .fr-video-upload-layer {
  border: dashed 2px gray;
  padding: 25px 0;
  margin: 20px;
}
.dark-theme.fr-popup .fr-video-upload-layer:hover {
  background: #424242;
}
.dark-theme.fr-popup .fr-video-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
.dark-theme.fr-popup .fr-video-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme.fr-popup .fr-video-upload-layer .fr-form input {
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
}
.dark-theme.fr-popup .fr-video-progress-bar-layer > h3 {
  margin: 10px 0;
}
.dark-theme.fr-popup .fr-video-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
.dark-theme.fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.dark-theme.fr-popup .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.dark-theme.fr-video-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
}
.dark-theme .fr-view img.fr-rounded,
.dark-theme .fr-view .fr-img-caption.fr-rounded img {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme .fr-view img.fr-shadow,
.dark-theme .fr-view .fr-img-caption.fr-shadow img {
  -webkit-box-shadow: 10px 10px 5px 0px #cccccc;
  -moz-box-shadow: 10px 10px 5px 0px #cccccc;
  box-shadow: 10px 10px 5px 0px #cccccc;
}
.dark-theme .fr-view img.fr-bordered {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-view .fr-img-caption.fr-bordered img {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme .fr-view span[style~='color:'] a {
  color: inherit;
}
.dark-theme .fr-view strong {
  font-weight: 700;
}
.dark-theme .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: lightgray;
}
.dark-theme .fr-view table td,
.dark-theme .fr-view table th {
  border: 1px solid #ddd;
}
.dark-theme .fr-view table th {
  background: #ececec;
}
.dark-theme .fr-view hr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .fr-view[dir='rtl'] blockquote {
  border-right: solid 2px #5e35b1;
  margin-right: 0;
  padding-left: 0;
}
.dark-theme .fr-view[dir='rtl'] blockquote blockquote {
  border-color: #00bcd4;
}
.dark-theme .fr-view[dir='rtl'] blockquote blockquote blockquote {
  border-color: #43a047;
}
.dark-theme .fr-view blockquote {
  border-left: solid 2px #5e35b1;
  margin-left: 0;
  color: #5e35b1;
}
.dark-theme .fr-view blockquote blockquote {
  border-color: #00bcd4;
  color: #00bcd4;
}
.dark-theme .fr-view blockquote blockquote blockquote {
  border-color: #43a047;
  color: #43a047;
}
.dark-theme .fr-view span.fr-emoticon {
  line-height: 0;
}
.dark-theme .fr-view span.fr-emoticon.fr-emoticon-img {
  font-size: inherit;
}
.dark-theme .fr-view .fr-text-bordered {
  padding: 10px 0;
}
.dark-theme .fr-view .fr-class-highlighted {
  background-color: #ffff00;
}
.dark-theme .fr-view .fr-class-code {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #353535;
}
.dark-theme .fr-view img.fr-dib {
  margin: 5px auto;
}
.dark-theme .fr-view img.fr-dib.fr-fil {
  margin-left: 0;
}
.dark-theme .fr-view img.fr-dib.fr-fir {
  margin-right: 0;
}
.dark-theme .fr-view img.fr-dii {
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.dark-theme .fr-view img.fr-dii.fr-fil {
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.dark-theme .fr-view img.fr-dii.fr-fir {
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.dark-theme .fr-view span.fr-img-caption.fr-dib {
  margin: 5px auto;
}
.dark-theme .fr-view span.fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
}
.dark-theme .fr-view span.fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
}
.dark-theme .fr-view span.fr-img-caption.fr-dii {
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.dark-theme .fr-view span.fr-img-caption.fr-dii.fr-fil {
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.dark-theme .fr-view span.fr-img-caption.fr-dii.fr-fir {
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.dark-theme .fr-view .fr-video.fr-rv {
  height: 0;
}
.dark-theme .fr-view .fr-video.fr-rv > iframe,
.dark-theme .fr-view .fr-video.fr-rv object,
.dark-theme .fr-view .fr-video.fr-rv embed {
  top: 0;
  left: 0;
}
.dark-theme .fr-view .fr-video > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme .fr-view a.fr-strong {
  font-weight: 700;
}
.dark-theme .fr-view .fr-img-caption .fr-img-wrap {
  padding: 0;
  margin: auto;
}
.dark-theme .fr-view .fr-img-caption .fr-img-wrap img {
  margin: auto;
}
.dark-theme .fr-view .fr-img-caption .fr-img-wrap > span {
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme .fr-view button.fr-rounded,
.dark-theme .fr-view input.fr-rounded,
.dark-theme .fr-view textarea.fr-rounded {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme a.fr-view.fr-strong {
  font-weight: 700;
}
.dark-theme img.fr-view.fr-dib {
  margin: 5px auto;
}
.dark-theme img.fr-view.fr-dib.fr-fil {
  margin-left: 0;
}
.dark-theme img.fr-view.fr-dib.fr-fir {
  margin-right: 0;
}
.dark-theme img.fr-view.fr-dii {
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.dark-theme img.fr-view.fr-dii.fr-fil {
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.dark-theme img.fr-view.fr-dii.fr-fir {
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.dark-theme span.fr-img-caption.fr-view.fr-dib {
  margin: 5px auto;
}
.dark-theme span.fr-img-caption.fr-view.fr-dib.fr-fil {
  margin-left: 0;
}
.dark-theme span.fr-img-caption.fr-view.fr-dib.fr-fir {
  margin-right: 0;
}
.dark-theme span.fr-img-caption.fr-view.fr-dii {
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.dark-theme span.fr-img-caption.fr-view.fr-dii.fr-fil {
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.dark-theme span.fr-img-caption.fr-view.fr-dii.fr-fir {
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.dark-theme .fr-element .fr-embedly {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .fr-element .fr-embedly::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dark-theme .fr-element .fr-embedly > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme.fr-box .fr-embedly-resizer {
  border: solid 1px #0098f7;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme.fr-popup .fr-icon.fr-fa-icon {
  width: 18px;
  padding: 8px;
  margin: 6px;
  font-size: 16px;
}
@supports not (-ms-high-contrast: none) {
  .dark-theme.fr-popup .fr-icon-container.fr-fa-container {
    grid-template-columns: repeat(auto-fill, minmax(45px, 45px));
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .dark-theme.fr-popup .fr-icon-container.fr-fa-container {
    width: 506px;
  }
}
.dark-theme .tui-image-editor-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.dark-theme .tui-editor-cancel-btn {
  background-color: #fff;
  border: 1px solid #aaa;
}
.dark-theme .tui-editor-save-btn {
  color: #fff;
}
.dark-theme .examples-variante > a {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.dark-theme .sc-cm-holder > .sc-cm {
  border-top: 5px solid #222222 !important;
}
.dark-theme .sc-cm__item_dropdown:hover > a,
.dark-theme .sc-cm a:hover {
  background-color: #424242 !important;
}
.dark-theme .sc-cm__item_active > a,
.dark-theme .sc-cm__item_active > a:hover,
.dark-theme .sc-cm a:active,
.dark-theme .sc-cm a:focus {
  background-color: #424242 !important;
}
.dark-theme .sc-cm-holder > .sc-cm:before {
  background-color: #424242 !important;
}
.dark-theme .fr-tooltip {
  top: 0;
  left: 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #222222;
  color: #fff;
  font-size: 11px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dark-theme .fr-tooltip.fr-visible {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-toolbar .fr-command.fr-btn,
.dark-theme.fr-popup .fr-command.fr-btn,
.dark-theme.fr-modal .fr-command.fr-btn {
  color: #fff;
  -moz-outline: 0;
  border: 0;
  margin: 4px 2px;
  padding: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  height: 40px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-options,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-options,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown.fr-options {
  border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-btn-hover,
.dark-theme.fr-popup .fr-command.fr-btn.fr-btn-hover,
.dark-theme.fr-modal .fr-command.fr-btn.fr-btn-hover {
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar .fr-command.fr-btn::-moz-focus-inner,
.dark-theme.fr-popup .fr-command.fr-btn::-moz-focus-inner,
.dark-theme.fr-modal .fr-command.fr-btn::-moz-focus-inner {
  border: 0;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-btn-text,
.dark-theme.fr-popup .fr-command.fr-btn.fr-btn-text,
.dark-theme.fr-modal .fr-command.fr-btn.fr-btn-text {
  width: auto;
}
.dark-theme.fr-toolbar .fr-command.fr-btn i,
.dark-theme.fr-toolbar .fr-command.fr-btn svg,
.dark-theme.fr-popup .fr-command.fr-btn i,
.dark-theme.fr-popup .fr-command.fr-btn svg,
.dark-theme.fr-modal .fr-command.fr-btn i,
.dark-theme.fr-modal .fr-command.fr-btn svg {
  margin: 8px 7px;
  width: 24px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn svg.fr-svg,
.dark-theme.fr-popup .fr-command.fr-btn svg.fr-svg,
.dark-theme.fr-modal .fr-command.fr-btn svg.fr-svg {
  height: 24px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn svg path,
.dark-theme.fr-popup .fr-command.fr-btn svg path,
.dark-theme.fr-modal .fr-command.fr-btn svg path {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  fill: #fff;
}
.dark-theme.fr-toolbar .fr-command.fr-btn span.fr-sr-only,
.dark-theme.fr-popup .fr-command.fr-btn span.fr-sr-only,
.dark-theme.fr-modal .fr-command.fr-btn span.fr-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.dark-theme.fr-toolbar .fr-command.fr-btn span,
.dark-theme.fr-popup .fr-command.fr-btn span,
.dark-theme.fr-modal .fr-command.fr-btn span {
  font-size: 14px;
  line-height: 17px;
  min-width: 30px;
  height: 17px;
  padding: 0 4px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn img,
.dark-theme.fr-popup .fr-command.fr-btn img,
.dark-theme.fr-modal .fr-command.fr-btn img {
  margin: 8px 7px;
  width: 24px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
.dark-theme.fr-popup .fr-command.fr-btn.fr-btn-active-popup,
.dark-theme.fr-modal .fr-command.fr-btn.fr-btn-active-popup {
  background: #4f4f4f;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown svg,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown i,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown span,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown img,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown svg,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown i,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown span,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown img,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown svg {
  margin-left: 3px;
  margin-right: 11px;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown:after,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown:after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
  right: 2px;
  top: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
  fill: #fff;
  background: #4f4f4f;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
  background: #424242;
  fill: #fff;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
  border-top-color: #fff;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after,
.dark-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:after,
.dark-theme.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:after {
  border-top: 0;
  border-bottom: 4px solid #fff;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-disabled,
.dark-theme.fr-popup .fr-command.fr-btn.fr-disabled,
.dark-theme.fr-modal .fr-command.fr-btn.fr-disabled {
  color: gray;
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.dark-theme.fr-popup .fr-command.fr-btn.fr-disabled::after,
.dark-theme.fr-modal .fr-command.fr-btn.fr-disabled::after {
  border-top-color: gray;
}
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn {
  margin: 0;
  width: 46px;
  height: 40px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
  background: #353535;
}
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn span,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn span,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn span {
  height: 27px;
}
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn img,
.dark-theme.fr-toolbar .fr-tabs .fr-command.fr-btn svg,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn img,
.dark-theme.fr-popup .fr-tabs .fr-command.fr-btn svg,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn img,
.dark-theme.fr-modal .fr-tabs .fr-command.fr-btn svg {
  margin: 8px 11px;
  width: 24px;
  height: 24px;
}
.dark-theme.fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
.dark-theme.fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
  fill: #0098f7;
}
.dark-theme.fr-toolbar.fr-disabled .fr-btn,
.dark-theme.fr-toolbar.fr-disabled .fr-btn.fr-active,
.dark-theme.fr-popup.fr-disabled .fr-btn,
.dark-theme.fr-popup.fr-disabled .fr-btn.fr-active,
.dark-theme.fr-modal.fr-disabled .fr-btn,
.dark-theme.fr-modal.fr-disabled .fr-btn.fr-active {
  color: gray;
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.dark-theme.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.dark-theme.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.dark-theme.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.dark-theme.fr-modal.fr-disabled .fr-btn.fr-dropdown::after,
.dark-theme.fr-modal.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: gray;
}
.dark-theme.fr-toolbar.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
.dark-theme.fr-toolbar.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options,
.dark-theme.fr-popup.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
.dark-theme.fr-popup.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options,
.dark-theme.fr-modal.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
.dark-theme.fr-modal.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options {
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar.fr-rtl .fr-command.fr-btn.fr-btn-hover,
.dark-theme.fr-toolbar.fr-rtl .fr-btn-wrap.fr-btn-hover,
.dark-theme.fr-popup.fr-rtl .fr-command.fr-btn.fr-btn-hover,
.dark-theme.fr-popup.fr-rtl .fr-btn-wrap.fr-btn-hover,
.dark-theme.fr-modal.fr-rtl .fr-command.fr-btn.fr-btn-hover,
.dark-theme.fr-modal.fr-rtl .fr-btn-wrap.fr-btn-hover {
  border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar.fr-inline > .fr-command.fr-btn:not(.fr-hidden),
.dark-theme.fr-toolbar.fr-inline > .fr-btn-wrap:not(.fr-hidden) {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.dark-theme.fr-desktop .fr-command:hover,
.dark-theme.fr-desktop .fr-command:focus,
.dark-theme.fr-desktop .fr-command.fr-btn-hover,
.dark-theme.fr-desktop .fr-command.fr-expanded {
  outline: 0;
  color: #fff;
}
.dark-theme.fr-desktop .fr-command:hover:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command:focus:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
  background: #424242;
}
.dark-theme.fr-desktop .fr-command:hover::after,
.dark-theme.fr-desktop .fr-command:focus::after,
.dark-theme.fr-desktop .fr-command.fr-btn-hover::after,
.dark-theme.fr-desktop .fr-command.fr-expanded::after {
  border-top-color: #fff;
}
.dark-theme.fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
.dark-theme.fr-desktop .fr-command:active {
  color: #fff;
  background: #4f4f4f;
}
.dark-theme.fr-desktop .fr-command.fr-active:hover,
.dark-theme.fr-desktop .fr-command.fr-active:focus,
.dark-theme.fr-desktop .fr-command.fr-active.fr-btn-hover,
.dark-theme.fr-desktop .fr-command.fr-active.fr-expanded {
  background: #424242;
}
.dark-theme.fr-desktop .fr-command.fr-active:active {
  background: #4f4f4f;
}
.dark-theme.fr-toolbar.fr-mobile .fr-command.fr-blink,
.dark-theme.fr-popup.fr-mobile .fr-command.fr-blink {
  background: #4f4f4f;
}
.dark-theme .fr-command.fr-btn.fr-options {
  width: 16px;
  margin-left: -5px;
}
.dark-theme .fr-command.fr-btn.fr-options.fr-btn-hover,
.dark-theme .fr-command.fr-btn.fr-options:hover,
.dark-theme .fr-command.fr-btn.fr-options:focus {
  border-left: solid 1px #fafafa;
  -webkit-transition: border-left 0s, background-color 0.5s;
  -moz-transition: border-left 0s, background-color 0.5s;
  -ms-transition: border-left 0s, background-color 0.5s;
  -o-transition: border-left 0s, background-color 0.5s;
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu {
  background: #353535;
  right: auto;
  bottom: auto;
  height: auto;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu.test-height .fr-dropdown-wrapper {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  height: auto;
  max-height: 275px;
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
  padding: 0;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  margin-top: 0;
  height: 0;
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
  margin: 0;
  padding: 8px 0;
  min-width: 72px;
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  padding: 0 20px;
  color: inherit;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme
  .fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a
  svg {
  width: 24px;
  height: 24px;
  margin: 3px 4px;
}
.dark-theme
  .fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a
  svg
  path {
  fill: #fff;
}
.dark-theme
  .fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a.fr-active {
  background: #4f4f4f;
}
.dark-theme
  .fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a.fr-disabled {
  color: gray;
}
.dark-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dark-theme .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-modal {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dark-theme.fr-modal.fr-middle .fr-modal-wrapper {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.dark-theme.fr-modal .fr-modal-wrapper {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin: 20px auto;
  background: #353535;
  min-width: 300px;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .dark-theme.fr-modal .fr-modal-wrapper {
    margin: 30px auto;
  }
}
@media (min-width: 992px) {
  .dark-theme.fr-modal .fr-modal-wrapper {
    margin: 50px auto;
  }
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-head {
  background: #353535;
  min-height: 42px;
  border-bottom: solid #efefef 1px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-head-line {
  height: 56px;
  padding: 0 10px;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-close {
  margin: 10px;
  top: 0;
  right: 0;
}
.dark-theme.fr-modal .fr-modal-wrapper .fr-modal-head h4 {
  font-size: 20px;
  padding: 19px 10px;
  margin: 0;
  font-weight: 400;
  line-height: 18px;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body {
  min-height: 150px;
  overflow-y: auto;
  padding-bottom: 20px;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body:focus {
  outline: 0;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
  height: 36px;
  color: #0098f7;
  padding: 10px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover,
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
  background: #424242;
  color: #0098f7;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
  background: #4f4f4f;
  color: #0098f7;
}
.dark-theme.fr-modal .fr-modal-wrapper div.fr-modal-body button::-moz-focus-inner {
  border: 0;
}
.dark-theme.fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
  background: #424242;
}
.dark-theme.fr-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-popup {
  color: #fff;
  background: #353535;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dark-theme.fr-popup .fr-icon {
  font-size: 20px;
  font-weight: 400;
  padding: 6px;
}
.dark-theme.fr-popup .fr-icon-container {
  padding: 20px;
  max-height: 200px;
  overflow: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@supports not (-ms-high-contrast: none) {
  .dark-theme.fr-popup .fr-icon-container {
    grid-template-columns: repeat(auto-fill, minmax(36px, 36px));
  }
}
@media (min-width: 768px) {
  .dark-theme.fr-popup .fr-icon-container {
    min-width: 276px;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 768px), (-ms-high-contrast: none) and (max-width: 768px) {
  .dark-theme.fr-popup .fr-icon-container {
    width: 236px;
  }
}
.dark-theme.fr-popup .fr-input-focus {
  background: #363636;
}
.dark-theme.fr-popup.fr-above {
  border-top: 0;
}
.dark-theme.fr-popup.fr-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-popup .fr-input-line {
  padding: 15px 0;
}
.dark-theme.fr-popup .fr-input-line input[type='text'],
.dark-theme.fr-popup .fr-input-line textarea {
  margin-bottom: 1px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: solid 1px #999999;
  color: #fff;
  font-size: 14px;
  background: #353535;
  padding: 4px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border 0.5s, padding 0.5s;
  -moz-transition: border 0.5s, padding 0.5s;
  -ms-transition: border 0.5s, padding 0.5s;
  -o-transition: border 0.5s, padding 0.5s;
}
.dark-theme.fr-popup .fr-input-line input[type='text']:hover,
.dark-theme.fr-popup .fr-input-line textarea:hover {
  border: solid 1px #515151;
}
.dark-theme.fr-popup .fr-input-line input[type='text']:focus,
.dark-theme.fr-popup .fr-input-line textarea:focus {
  border: solid 2px #0098f7;
  padding: 3px 11px;
}
.dark-theme.fr-popup .fr-input-line input[type='text'] {
  height: 46px;
}
.dark-theme.fr-popup .fr-input-line input + label,
.dark-theme.fr-popup .fr-input-line textarea + label {
  top: 29px;
  left: 12px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #353535;
  padding: 0;
}
.dark-theme.fr-popup .fr-input-line input.fr-not-empty + label,
.dark-theme.fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: gray;
  width: auto;
  left: 4px;
  padding: 0 4px;
  font-size: 11px;
  top: 9px;
}
.dark-theme.fr-popup input,
.dark-theme.fr-popup textarea {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-popup .fr-buttons {
  line-height: 0;
}
.dark-theme.fr-popup .fr-buttons .fr-btn {
  margin-left: 4px;
  margin-right: 4px;
}
.dark-theme.fr-popup .fr-buttons.fr-tabs {
  border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #686868;
}
@media (max-width: 768px) {
  .dark-theme.fr-popup .fr-buttons.fr-tabs.fr-tabs-scroll {
    width: 276px;
  }
}
.dark-theme.fr-popup .fr-buttons:not(.fr-tabs) {
  padding: 5px;
}
.dark-theme.fr-popup .fr-layer {
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 195px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 20px;
}
@media (min-width: 768px) {
  .dark-theme.fr-popup .fr-layer {
    width: 260px;
  }
}
.dark-theme.fr-popup .fr-action-buttons {
  height: 36px;
}
.dark-theme.fr-popup .fr-action-buttons button.fr-command {
  height: 36px;
  color: #0098f7;
  padding: 10px;
  font-size: 16px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-popup .fr-action-buttons button.fr-command:hover,
.dark-theme.fr-popup .fr-action-buttons button.fr-command:focus {
  background: #424242;
  color: #0098f7;
}
.dark-theme.fr-popup .fr-action-buttons button.fr-command:active {
  background: #4f4f4f;
  color: #0098f7;
}
.dark-theme.fr-popup .fr-action-buttons button::-moz-focus-inner {
  border: 0;
}
.dark-theme.fr-popup .fr-checkbox {
  width: 18px;
  height: 18px;
  padding: 10px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dark-theme.fr-popup .fr-checkbox svg {
  margin-left: 2px;
  margin-top: 2px;
  width: 10px;
  height: 10px;
}
.dark-theme.fr-popup .fr-checkbox span {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 18px;
  height: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.dark-theme.fr-popup .fr-checkbox input {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  height: 18px;
  margin: 0;
  padding: 0;
  width: 18px;
}
.dark-theme.fr-popup .fr-checkbox input:not(:checked) + span {
  border: solid 2px #999999;
}
.dark-theme.fr-popup .fr-checkbox input:not(:checked):active + span {
  background-color: #353535;
}
.dark-theme.fr-popup .fr-checkbox input:not(:checked):focus + span,
.dark-theme.fr-popup .fr-checkbox input:not(:checked):hover + span {
  border-color: #515151;
}
.dark-theme.fr-popup .fr-checkbox input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
.dark-theme.fr-popup .fr-checkbox input:checked:active + span {
  background-color: #ecf5ff;
}
.dark-theme.fr-popup .fr-checkbox input:checked:focus + span,
.dark-theme.fr-popup .fr-checkbox input:checked:hover + span {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}
.dark-theme.fr-popup .fr-checkbox-line {
  font-size: 14px;
  line-height: 1.4px;
  margin-top: 10px;
}
.dark-theme.fr-popup .fr-checkbox-line label {
  margin: 0 5px;
}
.dark-theme.fr-popup.fr-rtl .fr-input-line input + label,
.dark-theme.fr-popup.fr-rtl .fr-input-line textarea + label {
  left: auto;
  right: 0;
}
.dark-theme .fr-text-edit-layer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme.fr-toolbar {
  color: #fff;
  background: #353535;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 0;
  -webkit-transition: padding-bottom 0.5s;
  -moz-transition: padding-bottom 0.5s;
  -ms-transition: padding-bottom 0.5s;
  -o-transition: padding-bottom 0.5s;
}
.dark-theme.fr-toolbar::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}
.dark-theme.fr-toolbar::after {
  height: 0;
}
.dark-theme.fr-toolbar .fr-newline {
  margin-left: 9px;
  margin-right: 9px;
  background: #353535;
  height: 1px;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
}
.dark-theme.fr-toolbar.fr-toolbar-open {
  padding-bottom: 48px;
}
.dark-theme.fr-toolbar.fr-toolbar-open .fr-newline {
  height: 0;
}
.dark-theme.fr-toolbar .fr-more-toolbar {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #686868;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dark-theme.fr-toolbar .fr-more-toolbar.fr-expanded {
  height: 48px;
}
.dark-theme.fr-toolbar .fr-more-toolbar > .fr-command.fr-btn {
  margin-left: 4px;
  margin-right: 4px;
}
.dark-theme.fr-toolbar .fr-btn-grp {
  margin: 0 17px 0 12px;
}
@media (max-width: 768px) {
  .dark-theme.fr-toolbar .fr-btn-grp {
    margin: 0 7px 0 6px;
  }
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-open {
  margin-top: 10px;
  margin-bottom: -1px;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
  background: #686868;
}
.dark-theme.fr-toolbar.fr-inline {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  z-index: 2147483630;
}
.dark-theme.fr-toolbar.fr-inline.fr-above {
  border-top: 0;
}
.dark-theme.fr-toolbar.fr-inline .fr-newline {
  height: 0;
}
.dark-theme.fr-toolbar.fr-top {
  top: 0;
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar.fr-bottom {
  bottom: 0;
  border-top: 0;
  padding-bottom: 0;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar.fr-bottom .fr-newline {
  padding-top: 0;
  -webkit-transition: padding-top 0.5s;
  -moz-transition: padding-top 0.5s;
  -ms-transition: padding-top 0.5s;
  -o-transition: padding-top 0.5s;
}
.dark-theme.fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
  padding-top: 48px;
  background: #fff;
}
.dark-theme.fr-toolbar.fr-bottom .fr-command.fr-btn.fr-open {
  margin-top: -1px;
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme.fr-toolbar.fr-sticky-on {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme .fr-separator {
  background: #595959;
}
.dark-theme .fr-separator.fr-vs {
  height: 32px;
  width: 1px;
  margin: 8px 4px;
}
.dark-theme .fr-separator.fr-hs {
  width: calc(100% - (2 * 4px));
  margin: 0 4px;
  height: 1px;
}
.dark-theme .second-toolbar {
  border: 1px solid #cccccc;
  border-top: 0;
  background: #fff;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dark-theme .second-toolbar::after {
  clear: both;
  display: block;
  content: '';
  height: 0;
}
.dark-theme #logo > span {
  padding: 11px 5px 10px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #b1b2b7;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
}
.dark-theme #logo > svg {
  height: 20px;
  width: 47px;
  margin: 7px 0;
}
.dark-theme #logo > svg * {
  -webkit-transition: fill 0.3s;
  -moz-transition: fill 0.3s;
  -ms-transition: fill 0.3s;
  -o-transition: fill 0.3s;
}
.dark-theme #logo:hover > span,
.dark-theme #logo:focus > span {
  color: #0098f7;
}
.dark-theme #logo:hover > svg .fr-logo,
.dark-theme #logo:focus > svg .fr-logo {
  fill: #0098f7;
}

.dark-theme.fr-popup .fr-color-set.fr-selected-set {
  padding-bottom: 20px;
}