$input-selected-color: #0098f7;

.fr-plugin {
  &__module-link,
  &__challenge-response,
  &__anchor-link,
  &__anchor-link-id,
  &__conditional-steps,
  &__lights,
  &__file-link-id,
  &__dc-type {
    margin: 20px;
    width: 250px;

    &__container {
      display: flex;
      flex-direction: column;
    }

    .MuiFormControl-root {
      margin-bottom: 16px;

      .MuiFormLabel-root.Mui-focused {
        color: $input-selected-color;
      }

      .MuiInputLabel-outlined {
        transform: translate(14px, 18px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -2px) scale(0.75);
        }
      }

      .MuiOutlinedInput-input {
        padding: calc($base-padding / 2) #{$base-padding};
        font-size: 15px;
        height: 38px;

        &.MuiSelect-root {
          padding-right: 38px;
          background-color: initial;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-radius: 2px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
      .MuiInput-underline::after {
        border-color: $input-selected-color;
      }
    }

    .MuiButtonBase-root {
      margin-left: auto;
      color: $input-selected-color;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      padding: 10px;
    }
  }
}
