.clear-button {
  color: $blue-color-all-themes;
  svg {
    margin-right: 0.5rem;
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    svg {
      fill: rgba(0, 0, 0, 0.26);
    }
    pointer-events: all;
    cursor: not-allowed;
  }

  &:not(.Mui-disabled) {
    svg {
      fill: $blue-color-all-themes;
    }
  }
}

.dark {
  .clear-button {
    color: $blue-color-all-themes;
    svg {
      fill: $blue-color-all-themes;
    }
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.3) !important;
      svg {
        fill: rgba(255, 255, 255, 0.3) !important;
      }
      pointer-events: all;
      cursor: not-allowed;
    }
  }
}

.filter-config-selector {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 1em;



  .apply-filter-button-wrapper {
    position: relative;
    .error {
      position: absolute;
      left: 0;
      top: 3em;
      color: #f24725;
    }
  }
  .apply-button {
    &.Mui-disabled {
      pointer-events: all;
      cursor: not-allowed;
    }
  }
}

.compliance-filter-no-items-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 65rem;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 8px;
  div {
    align-self: center;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.compliance-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .compliance-documents-table-container {
    border: 1px solid $border-color;
    border-radius: 8px;
    height: 100%;
    position: relative;
    .compliance-documents-table {
      position: absolute;
      max-height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      tbody {
        background-color: $reader-default-color-invert;
        .document-row {
          &:hover {
            cursor: pointer;
            background: $background-light-blue-color;
          }
        }
      }
      .MuiTableSortLabel-active {
        font-weight: bold;
      }
    }
  }
  .compliance-no-filter-applied {
    align-self: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}


.progress-bar {
  display: flex;
  flex-wrap: wrap;
  width: 5rem;
  .progress-bar-level {
    display: flex;
    height: 0.375rem;
    width: 100%;
    .positive-bar {
      background: #8ed14f;
    }
    .negative-bar {
      background: #f24725;
      &.future {
        background: #ff7700;
      }
    }
  }
  .progress-bar-percentage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 0.625rem;
  }
}

.changes-wrapper {
  .search-bar {
    width: 100%;
    max-width: 20em;
    background-color: $reader-default-color-invert;
    margin-left: 1em;
    margin-top: 1em;
    margin-bottom: 0;
  }

  // Changes table
  .changes-table {
    background-color: $reader-default-color-invert;
  }
  .compliance-documents-table {
    background-color: $reader-default-color-invert;
    border: 1px solid $border-color;
    border-radius: 8px;
  }
}
.search-and-table-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: $reader-default-color-invert;
  border-radius: 8px;
  border: 1px solid $border-color;
  overflow: hidden;
}

// Changes filter in table
.changes-filter-wrapper {
  position: absolute;
  background-color: $reader-default-color-invert;
}

.status-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  &.green {
    background-color: #8ed14f;
  }
  &.red {
    background-color: #f24725;
  }
}

.compliance-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1em;
  //Sidebar compliance
  .status-sidebar {
    margin-top: 1em;
    flex: 0 0 10rem;
    width: 10em;
    position: relative;
    flex-direction: column;
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .sidebar-title {
      margin-bottom: 1em;
    }
    p {
      font-size: 1.125em;
      margin: 0px;
    }
    .sidebar-button {
      background-color: transparent;
      cursor: pointer;
      padding: 0.3125em 1.25em;
      &.active {
        background-color: $blue-color-all-themes;
        color: $white-color-all-themes;
      }
    }
    .sidebar-presets {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .presets-title {
        margin-bottom: 1rem;
        p {
          font-size: 1.125em;
        }
      }
      p {
        font-size: 0.875rem;
      }
      .preset-list-items {
        padding-left: 1.25em;
        position: relative;
        height: 100%;
        .preset-list-items-scroll-wrapper {
          position: absolute;
          left: 1.25em;
          top: 0;
          height: 100%;
          width: calc(100% - 1.25em);
          overflow-y: auto;
        }
        .preset-list-item {
          position: relative;
          margin-bottom: 0.5rem;
          width: 100%;
          > div {
            width: 100%;
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
          .delete-preset {
            display: none;
            position: absolute;
            right: 0px;
            top: 0.1rem;
            button {
              position: absolute;
              right: 0;
            }
          }
          &:hover {
            cursor: pointer;
            .delete-preset {
              display: block;
            }
            p {
              max-width: 7rem;
            }
          }
          &.active {
            font-weight: bold;
            font-size: 1rem;
          }
          &.mobile {
            .delete-preset {
              display: block;
            }
            p {
              max-width: 7rem;
            }
          }
          .preset-status {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .compliance-content {
    display: flex;
    flex: 1 0 auto;
    padding: 0.5em;
    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-grow: 1;
      .document-details {
        width: 55%;
        margin-bottom: 1rem;
        .compliance-documents-table {
          background-color: $reader-default-color-invert;
          border: 1px solid $border-color;
          border-radius: 8px;
          background-color: $reader-default-color-invert;
        }
      }
    }

    .table-head-active {
      color: $reader-default-color-invert;
    }
  }

  .table-item-selected {
    background-color: rgba(92, 168, 243, 0.3);
  }
}

.compliance-heading {
  font-size: 1.25rem;
  margin: 0 0 0.5rem 0;
  margin-bottom: 0.5em;
}
.present-compliance-current-time {
  display: flex;
  gap: 1em;
  .datepicker-select {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    max-width: 180px;
    p {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 1rem;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 0;
    }
    .MuiOutlinedInput-input {
      padding: 0.6em;
    }
    .Mui-error {
      font-size: 1rem;
    }
    .MuiFormControl-root {
      margin-right: 0;
      max-width: 180px;
    }
    &.time-select {
      width: 134px;
      .MuiFormControl-root {
        margin-right: 0;
        max-width: 134px;
      }
    }
    .Mui-disabled {
      pointer-events: all;
      cursor: not-allowed;
    }
  }
}

// Changes and users
.changes-and-users-wrapper {
  display: flex;
  flex-grow: 1;
  gap: 1em;
  .changes-wrapper {
    flex-basis: 55%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 55%;
    .changes-table-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      flex-shrink: 1;
      flex-grow: 1;
      height: 100%;
      > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-shrink: 1;
        gap: 1em;
        .table-heading {
          p {
            margin: 1em 0 0 0;
          }
        }
        .table-wrapper {
          position: relative;
          flex-grow: 1;
        }
        .changes-table {
          position: absolute;
          height: 100%;
          tr {
            .arrow-right {
              svg {
                opacity: 0;
                transition: 0.2s ease-out;
              }
              &.selected {
                svg {
                  opacity: 1;
                }
              }
            }
            &:hover {
              cursor: pointer;
              background: $background-light-blue-color;
              .arrow-right {
                svg {
                  opacity: 1;
                  transition: 0.2s ease-in;
                }
              }
            }
          }
          .MuiTableSortLabel-active {
            font-weight: bold;
          }
        }
        .compliance-documents-table-container {
          min-height: 70px;
        }
      }
    }
  }
  .users-wrapper {
    flex: 1;
    .users-table-wrapper {
      height: 100%;
      > div {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        .table-heading {
          p {
            margin: 1em 0 0 0;
          }
        }
        .users-table-grow {
          position: relative;
          flex-grow: 1;
          .users-table {
            border: 1px solid $border-color;
            border-radius: 8px;
            position: absolute;
            height: 100%;
            background-color: $reader-default-color-invert;
            .MuiTableSortLabel-active {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.type-filter {
  position: relative;
  .filter-button {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
    }
    svg {
      transform: rotate(180deg);
    }
    &.active {
       svg {
        transform: rotate(0deg);
       }
    }

  }
}

.presets-header {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  .preset-title {
    font-size: 1rem;
    margin-right: 1.5rem;
  }
  .save-preset {
    font-size: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background: transparent;
    border: none;
    color: $blue-color-all-themes;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.compliance-export-spinner {
  flex: 1;
  flex-direction: row;
  .spinner {
    width: 1.125rem !important;
    height: 1.125rem !important;
    color: black;
  }
}

.compliance-kebab-menu-icon {
  .MuiSvgIcon-root {
    fill: $highlight-text-color;
  }
}

