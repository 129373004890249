$default-pad-left-decimal-type: 24px;
@mixin decimal-type-list($padding-left, $before-width, $dot) {
  > li {
    position: relative;
    padding-left: $padding-left;
    @if $dot {
      &:before {
        width: $before-width;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        content: counters(item, '.') '. ';
      }
    } @else {
      &:before {
        width: $before-width;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        content: counters(item, '.');
      }
    }
  }
}

@mixin content-style-froala {
  table {
    $sizes: (
      '.Width_25': 25%,
      '.Width_33': 33%,
      '.Width_50': 50%,
      '.Width_66': 66%,
      '.Width_75': 75%,
      '.Width_100': 100%,
    );
    @each $size, $width in $sizes {
      &#{$size} {
        width: $width !important;
      }
    }

    &.Align_Center {
      margin-left: auto;
      margin-right: auto;
    }

    /* Next rule should only be applied in the editor, not in other views */
    /*
    &.Hide_Borders td,
    &.Hide_Borders th {
      border-style: dashed !important;
      border-color: #d3d3d3 !important;
      border-width: $border-width !important;
    }
     */

    /*
    &.Hide_Column_Borders {
      td,
      th {
        border-left-color: white !important;
        border-left-width: 1px;
        border-right-color: white !important;
        border-right-width: 1px;
      }
    }

    &.Hide_Row_Borders {
      td,
      th {
        border-top-color: white !important;
        border-top-width: 1px;
        border-bottom-color: white !important;
        border-bottom-width: 1px;
      }
    }
    */

    &.Alternate_Row_Colors {
      tbody {
        tr:nth-child(odd) {
          background-color: var(--reader-table-odd-background-color);
        }
      }
    }

    /*
    td,
    th {
      &.Hide_Bottom_Border {
        border-bottom-color: white !important;
        border-bottom-width: $border-width;
      }

      &.Hide_Right_Border {
        border-right-color: white !important;
        border-right-width: $border-width;
      }
    }
    */
  }

  img {
    $sizes: (
      '.Width_25': 25%,
      '.Width_33': 33%,
      '.Width_50': 50%,
      '.Width_66': 66%,
      '.Width_75': 75%,
      '.Width_100': 100%,
    );
    @each $size, $width in $sizes {
      &#{$size} {
        width: $width;
      }
    }

    $aligns: (
      '.fr-fil': 0 auto 0 0,
      '': 0 auto,
      '.fr-fir': 0 0 0 auto,
    );
    @each $align, $margin in $aligns {
      &#{$align} {
        margin: $margin;
      }
    }

    &.Show_Border {
      border: $border-width $border-type gray;
      padding: 2px;
    }
  }

  a {
    text-decoration: none;
    color: #5ca8f3;

    &:hover {
      text-decoration: underline;
    }

    &.procedure {
      color: black;
    }

    &.fr-command {
      color: black;
    }

    svg {
      width: 16px;
    }
  }
  
  .fr-element {
    [id^='target'] {
      &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M17,15l1.55,1.55c-0.96,1.69-3.33,3.04-5.55,3.37V11h3V9h-3V7.82C14.16,7.4,15,6.3,15,5c0-1.65-1.35-3-3-3S9,3.35,9,5 c0,1.3,0.84,2.4,2,2.82V9H8v2h3v8.92c-2.22-0.33-4.59-1.68-5.55-3.37L7,15l-4-3v3c0,3.88,4.92,7,9,7s9-3.12,9-7v-3L17,15z M12,4 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,4,12,4z'%3E%3C/path%3E%3C/svg%3E");
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
  }

  [id^='anchorLinkCreate'] {
    svg {
      height: 22px !important;
      margin-top: 10px;
    }
  }

  [id^='groupLinkCreateButton'] {
    svg {
      height: 24px !important;
      width: 26px;
      margin-top: 15px;
    }
  }

  [id^='groupTextAlignButton'] {
    width: 44px;
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #333333;
      right: 2px;
      top: 18px;
      content: "";
    }
  }

  /* Provided by Yonder */

  h2 {
    font-size: 11pt;
    font-weight: bold;
  }

  .caution,
  .important,
  .note,
  .warning {
    border: 2pt solid;
    margin-top: 8pt;
    margin-bottom: 8pt;
    padding: 4pt;
  }

  .caution {
    border-color: #f08421;
  }
  .note {
    border-color: #5083c5;
  }
  .warning {
    border-color: #dc000c;
  }

  .caption {
    font-size: smaller;
    font-weight: bolder;
  }

  /* Internal Comments */
  .internalcomment {
    background-color: #ffff00;
  }

  .internalcomment:before {
    content: 'Internal Comment';
    display: block;
    font-size: smaller;
    color: gray;
  }

  .fr-dropdown-list .internalcomment:before {
    display: none !important;
  }

  /* Table Defaults */

  td {
    vertical-align: top;
  }

  /* Pdf References: As default, Froala shows an icon after the <a> element. Hide this icon */
  a.fr-file:after {
    content: ' ' !important;
  }

  a.fr-file {
    text-decoration: none;
  }

  /* Actions */

  p.action {
    padding: 0;
    overflow-x: hidden;
    list-style: none;
    margin-top: 3pt;
    margin-bottom: 3pt;
  }

  p.action:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
  }

  p.action > span:first-child {
    padding-right: 0.33em;
    background: white;
  }

  p.action > span + span {
    float: right;
    padding-left: 0.33em;
    background: white;
  }

  p.action > span + span:empty:before {
    content: ' ';
    padding-right: 25px;
  }

  p.action > span + span:empty {
    background-color: lightgray;
    min-width: 100px;
  }

  ol,
  ul {
    list-style-position: initial;
  }

  ol.decimal_type {
    padding-inline-start: 0px;
    @include decimal-type-list($default-pad-left-decimal-type, $default-pad-left-decimal-type - 2, true);
    ol.decimal_type {
      @include decimal-type-list(
        $default-pad-left-decimal-type * 2 - 10,
        $default-pad-left-decimal-type * 2 - 10,
        false
      );
      ol.decimal_type {
        @include decimal-type-list(
          $default-pad-left-decimal-type * 3 - 20,
          $default-pad-left-decimal-type * 3 - 20,
          false
        );
        ol.decimal_type {
          @include decimal-type-list(
            $default-pad-left-decimal-type * 4 - 30,
            $default-pad-left-decimal-type * 4 - 30,
            false
          );
          ol.decimal_type {
            @include decimal-type-list(
              $default-pad-left-decimal-type * 5 - 40,
              $default-pad-left-decimal-type * 5 - 40,
              false
            );
          }
        }
      }
    }
  }
  /* End snipped*/

  hr.termination {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    height: 14px;
  }

  hr.termination::before {
    content: '\25A0 \00A0 \25A0 \00A0 \25A0 \00A0 \25A0';
    font-size: 20pt;
    color: var(--boeing-termination-color);
    display: inline-block;
    position: relative;
    top: -0.6em;
  }

  /* Condition */

  .condition {
    background-color: var(--boeing-objective-color);
    padding: 5px;
    padding-left: 64px;
    position: relative;
    &::before {
      content: 'Condition:';
      font-size: smaller;
      font-weight: normal;
      position: absolute;
      left: 8px;
      top: 8px;
    }
    p,
    ul,
    ol {
      margin-top: 1pt;
    }
  }

  .fr-dropdown-list .condition {
    font-weight: normal !important;
    &::before {
      display: none !important;
    }
    &:hover {
      background-color: lightgray;
    }
  }

  /* Objective */

  .objective {
    background-color: #ebebeb;
    padding: 5px;
    font-weight: bold;
    display: flex;
    &::before {
      content: 'Objective:';
      font-size: smaller;
      margin-right: 15px;
      font-weight: normal;
      height: 100%;
    }
  }

  .fr-dropdown-list .objective {
    font-weight: normal !important;
    &::before {
      display: none !important;
    }
    &:hover {
      background-color: lightgray;
    }
  }

  /* Callout */

  .callout {
    width: 35px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
    color: white;
    background-color: blue;
  }

  .choice-items {
    .choice-item {
      .condition-line {
        left: 7px;
      }
    }
  }

  /* Inline applicability v2 */
  .inline-applicability {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 35px;
    position: relative;
    .inline-applicability-inner {
      border-top: $border-width $border-type gray;
      border-right: $border-width $border-type gray;
      border-bottom: $border-width $border-type gray;
      padding: 5px;
    }
    .p-tags {
      text-align: right;
      br {
        display: none;
      }
    }
    .p-tags:before {
      content: attr(data-tag-filter-labels);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M22 16v-2l-8.5-5V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V9L2 14v2l8.5-2.5V19L8 20.5V22l4-1 4 1v-1.5L13.5 19v-5.5L22 16z'%3E%3C/path%3E%3C/svg%3E");
      background-size: 9px;
      background-repeat: no-repeat;
      background-position: 8px 2px;
      position: relative;
      right: 0;
      top: 0px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 16px;
      font-size: 11px;
      line-height: 14px;
      padding: 1px 8px 0 17px;
      display: inline-block;
    }
  }

  /*Dynamic content*/
  .dynamic-content {
    &[data-dc-locked='true'] {
      .dynamic-content-labels {
        &:before {
          display: block;
          content: 'The dynamic content is locked and will not be updated anymore.';
          color: red;
        }
      }
    }
    &[data-dc-locked='false'] {
      .dynamic-content-labels {
        &:before {
          display: block;
          content: 'The following content must not be changed as it is generated automatically and overwritten each time the document is published.';
        }
      }
    }

    .dynamic-content-revision-bulletin {
      td {
        border: none;
      }

      .summary {
        font-weight: bold;
      }
    }
  }
}

//Dark mode overwrites
.dark {
  .p-tags:before {
    color: #000 !important;
    filter: invert(100%);
  }
}
