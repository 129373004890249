.submissions-filter-no-items-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 8px;

  .submissions-no-filter-applied {
    align-self: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.submissions-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .submissions-search-field {
    width: 100%;
    max-width: 40em;
    margin-top: 8px;
    margin-bottom: 24px;

    &.error {
      .MuiFilledInput-underline {
        &:before, &:after {
          border-color: $search-error-color;
        }
      }
    }
  }

  .submissions-no-filter-applied {
    align-self: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}


.form-submissions-table-container {
  border: 1px solid $border-color;
  border-radius: 8px;
  height: 100%;
  position: relative;
  .form-submissions-table {
    position: absolute;
    max-height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    .submission-status {
      width: 240px;
    }
    .submission-date {
      width: 200px;
    }
    .submission-user {
      width: 200px;
    }
    .submission-title {
      width: 200px;
    }
    tbody {
      background-color: $reader-default-color-invert;
      .submission-row {
        &:hover {
          cursor: pointer;
          background: $background-light-blue-color;
        }
      }
    }
    .table-head-active {
      color: $reader-default-color-invert;
    }
    .MuiTableSortLabel-active {
      font-weight: bold;
    }
    .submission-badge {
      display: flex;
      align-items: center;
      margin: 0;
      justify-content: center;
      padding: 8px 0 6px 0;
    }
  }
}

