.item-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  .item-selector-search-bar {
    width: calc(100% - 1em);
  }
  .scrollable {
    overflow-y: auto;
    width: 100%;
  }
  .item-selector-all-items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .filter-list-item {
    padding: 0;
    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }

  .filter-list-item-non-multiselect {
    margin-left: 1em;
  }
}

.item-selector-search-bar {
  width: 100%;
  max-width: 20em;
  background-color: $reader-default-color-invert;
}

.item-selector-search-bar {
  &.error {
    .MuiInput-underline {
      &:before, &:after {
        border-bottom-color: $search-error-color;
      }
    }
  }
}
