$highlight-c1: var(--highlight-c1);
$highlight-c2: var(--highlight-c2);
$highlight-c3: var(--highlight-c3);
$highlight-c4: var(--highlight-c4);
$highlight-c5: var(--highlight-c5);
$highlight-c6: var(--highlight-c6);
$highlight-editor-hover: var(--highlightEditorHover);
$highlight-editor-background: var(--highlightEditorBackground);
$highlight-editor-toolbar: var(--highlightEditorToolbar);

.highlight-c1 {
  background-color: $highlight-c1;
}

.highlight-c2 {
  background-color: $highlight-c2;
}

.highlight-c3 {
  background-color: $highlight-c3;
}

.highlight-c4 {
  background-color: $highlight-c4;
}

.highlight-c5 {
  background-color: $highlight-c5;
}

.highlight-c6 {
  background-color: $highlight-c6;
}

// !important is used here to override the ones that are in document type stylesheets
.reader-content .module-version-content .content .document-content {
  .highlight-c1 {
    background-color: $highlight-c1 !important;
  }

  .highlight-c2 {
    background-color: $highlight-c2 !important;
  }

  .highlight-c3 {
    background-color: $highlight-c3 !important;
  }

  .highlight-c4 {
    background-color: $highlight-c4 !important;
  }

  .highlight-c5 {
    background-color: $highlight-c5 !important;
  }

  .highlight-c6 {
    background-color: $highlight-c6 !important;
  }

  img.highlight-c1 {
    border: 5px solid $highlight-c1 !important;
  }

  img.highlight-c2 {
    border: 5px solid $highlight-c2 !important;
  }

  img.highlight-c3 {
    border: 5px solid $highlight-c3 !important;
  }

  img.highlight-c4 {
    border: 5px solid $highlight-c4 !important;
  }

  img.highlight-c5 {
    border: 5px solid $highlight-c5 !important;
  }

  img.highlight-c6 {
    border: 5px solid $highlight-c6 !important;
  }

  .note-icon {
    position: relative;
    svg {
      position: absolute;
      left: -0.5em;
      top: -1em;
      width: 0.7em;
      color: black;
    }

    &.highlight-c1 svg {
      fill: $highlight-c1;
    }

    &.highlight-c2 svg {
      fill: $highlight-c2;
    }

    &.highlight-c3 svg {
      fill: $highlight-c3;
    }

    &.highlight-c4 svg {
      fill: $highlight-c4;
    }

    &.highlight-c5 svg {
      fill: $highlight-c5;
    }

    &.highlight-c6 svg {
      fill: $highlight-c6;
    }
  }
}

.dark .reader-content .module-version-content .content .document-content .note-icon svg {
  color: white;
}

.list-item-primary .note-icon,
p[class^="highlight"] {
  svg {
    width: 1em;
    height: 1em;
    padding-right: 0.1em;
    padding-top: 0.1em;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  position: relative;
  left: 10px;
  top: -1px;
  &.flipped {
    left: unset;
    right: 10px;
    float: right;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $highlight-editor-background;
  position: relative;
  left: 10px;
  z-index: 2;
  top: 1px;
  &.flipped {
    right: 10px;
    align-self: flex-end;
    left: -10px;
  }

  &.without-note {
    border-bottom-color: $highlight-editor-hover;
  }
}

$popup-z-index: 1200;

#highlight-popper {
  z-index: $popup-z-index;

  .highlight-color-picker {
    background-color: #000;
    color: #fff;
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    .highlight-dots {
      display: flex;
      margin-right: 0.313rem;

      .highlight-color-dot {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin: 5px;
        cursor: pointer;
        &.selected {
          outline: #fff solid 2px;
          box-shadow: 0 0 1px 0 white inset, 0 0 1px 0 white;
        }
      }
    }

    .highlights-divider {
      background-color: #fff;
    }

    .highlight-action-button {
      font-size: 0.875rem;
      margin: 0.313rem;
      vertical-align: middle;
      text-align: center;
      color: #fff;
      cursor: pointer;

      &.disabled {
        color: rgba(256, 256, 256, 0.5);
        cursor: not-allowed;
      }
    }
  }
}

$note-width: 25em;
$note-width-small-screen: 20em;
$small-screen-width: 470px;

.note-popper, .transfer-highlight-popper {
  width: $note-width;
  display: flex;
  flex-direction: column;
  z-index: $popup-z-index;

  @media (max-width: $small-screen-width) {
    width: $note-width-small-screen;
  }
}

#transfer-highlight-popper {
  z-index: 20;
  .arrow-up {
    border-bottom-color: $task-tab-button-background-inactive-dark;
  }
}

.transfer-highlight-popper {
  width: auto;
  max-width: $note-width;
  background-color: transparent;
  border-radius: 8px;

  &.note {
    background-color: $task-tab-button-background-inactive-dark;
  }

  .transfer-highlight-note {
    padding: 12px 20px;
    background-color: $task-tab-button-background-inactive-dark;
  }

  .transfer-highlight-actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 12px 20px;
    background-color: $task-tab-button-background-inactive-dark;
    border-radius: 0 0 8px 8px;
  }

  &.note {
    .transfer-highlight-actions {
      justify-content: center;
    }
  }

  .transfer-highlight-action-button {
    // color: #000;

    &.ignore {
      color: $task-tab-button-background-active-dark;
      border-color: $task-tab-button-background-active-dark;
    }
  }
}

.note-viewer {
  background-color: $highlight-editor-background !important;
  .toolbar {
    background-color: $highlight-editor-toolbar !important;
  }
  .transfer-button {
    background-color: $highlight-editor-hover !important;
  }
  .no-transfer-button {
  }
}

.note-editor {
  .dark-theme.fr-box.fr-basic .fr-element {
    color: unset;
  }
  .fr-box.fr-basic.fr-bottom .fr-wrapper {
    border: 0;
  }
  .fr-box {
    width: $note-width;
    z-index: 2;

    @media (max-width: $small-screen-width) {
      width: $note-width-small-screen;
    }
  }
  .fr-btn-hover {
    background-color: $highlight-editor-hover !important;
    border-color: $highlight-editor-hover !important;
  }
  .fr-btn {
    border-color: $highlight-editor-hover !important;
    &:hover {
      background-color: $highlight-editor-hover !important;
    }
  }
  .fr-wrapper {
    overflow-y: auto;
    background-color: $highlight-editor-background !important;
  }
  .fr-toolbar {
    border: 0;
    .fr-newline {
      display: none;
    }
    background-color: $highlight-editor-toolbar !important;
  }
  button[data-cmd="delete-note"][id^="delete-note"] {
    position: absolute;
    right: 0.75em;
  }
}

.dark .note-editor .fr-view {
  color: white;
}

.highlights-disabled-note {
  padding: 0.5em;
}

.layout--transfer-highlights {
  display: flex;
  .module-version-header {
    height: auto;
  }
  .transfer-highlights-sidebar {
    flex: 0 0 20.75rem;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border-right: 1px solid $border-color;
    .title-wrapper {
      padding: 1.875rem 0;
      border-bottom: 1px solid $border-color;
      .sidebar-title {
        padding: 0 2.25rem;
        font-size: 1.438rem;
        line-height: 2.5rem;
      }
    }
    .transfer-highlights-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      .tasks-tabs {
        display: flex;
        justify-content: space-evenly;
        padding: 4px 5px;
        margin: 0.2rem 0;
        .tasks-button {
          padding: 7px 20px;
          background-color: $task-tab-button-background;

          display: flex;
          align-items: center;
          border-radius: 7px;
          width: 50%;
          justify-content: space-evenly;

          &.open {
              transform: translateX(0.5rem);
          }
          &.done {
              margin-right: 0.5rem;
          }
          &.active {
              border: 0.1rem solid $task-tab-button-border;
              z-index: 1;
          }

          svg {
              margin-right: 11px;
          }

          .tasks-counter {
              margin-left: 4px;
              border-radius: 0.15em;
              display: flex;
              width: 1.2rem;
              height: 1.2rem;
              justify-content: center;
              background-color: $task-counter-background-inactive;
              &.active {
                  background-color: $task-counter-background-active;
              }
              color: $black-color-all-themes;
          }
          &.active {
              background-color: $white-color-all-themes;
          }
          &:hover {
              cursor: pointer;
          }
        }
      }
      .tasks-list {
        overflow: auto;

        ul {
          height: '100%;';
          padding: 0;
          margin: 0;
        }
      }
      li {
        display: flex;
        flex-wrap: wrap;
        .list-item-secondary {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .transfer-highlights-content {
    flex: 1 1 0%;
    height: 100%;
    overflow: auto;

    .transfer-highlights-content-select-highlight {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      p {
        border-radius: 20px;
        padding: 20px 25px;
        background-color: $task-tab-button-background;
      }
    }
  }
}
