
// Survey Js variable settings for styling purpose
:root {
  --primary: #5ca8f3;
  --secondary: #5ca8f3;
  --secondary-back-light: #e6f7ff;
  --secondary-light: #e6f7ff;
  // Dropdown question list item background color
  --sjs-questionpanel-hovercolor: #e6f7ff;
}

* {
  -webkit-overflow-scrolling: touch;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-touch-callout: none;
}

.grow {
  flex-grow: 1;
}

.dark {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 $main-ui-color;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(119, 114, 114, 0.54);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(119, 114, 114, 0.75);
  }
}
