.highlight {
  background-color: yellow !important;
}

.cr-highlight {
  background-color: yellow !important;
}

img.cr-highlight {
  border: #{$border-width * 2} $border-type yellow !important;
}

.change-request-highlight-bubble {
  width: 23px;
  text-align: center;
  vertical-align: middle;
  height: 23px;
  background-color: #ffca14;
  position: relative;
  bottom: 1.6em;
  border-radius: 23px / 23px;
  border: #{$border-width * 3} #{$border-type} #fff7a7;
}

.change-request-highlight-selector {
  max-height: 95%;
  overflow: auto;
}
