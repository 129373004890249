.drawer {
  margin: 0 0 0 $sidebar-width;
  min-width: calc(100vw - #{$sidebar-width});
  max-width: calc(100vw - #{$sidebar-width});
  overflow-x: auto;
  background-color: $background-color;
  border-right: $border-width $border-type $border-color;
  top: $navbar-height;
  left: 0;
  bottom: 0;
  transition-property: all;
  transition-duration: 0.5s;
  z-index: $zindex-drawer;
  position: absolute;

  &.hidden {
    left: -100vw;
  }

  @include media-breakpoint-up('sm') {
    min-width: $drawer-width;
    max-width: $drawer-width;

    &.hidden {
      left: -$drawer-width;
    }
  }
}

.container {
  .drawer {
    .container {
      padding: #{$container-padding * 2} 0 #{$container-padding * 2} 0;
      height: 100%;

      .toolbar {
        .title {
          padding: 0 0 0 #{$container-padding * 2};
        }
      }
    }
  }
}
