.filter-selector {
  .filter-domain {
    .MuiListItemIcon-root {
      min-width: 32px;
    }
  }

  .filter-divider {
    margin-left: $container-padding;
  }

  .collapse {
    padding-left: #{$base-padding * 2} !important;

    .list-item {
      border-bottom-width: $border-width;
      border-bottom-style: $border-type;
      border-bottom-color: $border-color;
      padding-left: 0;

      .checkbox {
        padding: 0;
      }
    }
  }

  @for $i from 1 through 8 {
    .text-layer-#{$i} {
      padding: 0 0 0 #{$i * 8}px !important;
    }
  }
}

.active-filters {
  padding: #{$base-padding * 2};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .clear {
    margin-top: 4px;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  .chip {
    margin-bottom: 4px;
    background-color: $chip-background-color;
    height: auto;
    min-height: 32px;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    span[class^='MuiChip-label'] {
      padding: 0;
      flex-grow: 1;
      white-space: normal;
    }

    svg[class^='MuiSvgIcon-root'] {
      margin: 0 $icon-padding !important;
      font-size: 14px;
    }

    img {
      margin: 0 $icon-padding !important;
      font-size: 14px;
    }
  }
}
