.conditional-list {
  .conditional-items {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .conditional-input-wrapper {
      margin-right: 8px;
      .MuiFormControl-root {
        margin-bottom: 0px;
      }
    }
    .remove-conditional-item {
      padding: 0px 5px;
    }
  }
}

.add-conditional-step {
  padding: 0px;
  color: #000;
  margin: 0;
  min-width: 10px;
  max-width: 24px;
  font-size: 24px;
}
