@mixin survey-js-library-overrides {
  .sd-page {
    padding-top: 10px;
  }

  .sd-file__sign {
    a {
      color: $text-color;
    }
  }

  .sd-rating__item-text.sd-rating__item-text, .sd-rating__item-smiley, .sd-boolean--disabled .sd-boolean__thumb, .sd-boolean--disabled .sd-boolean__label {
    opacity: 1;
    color: $text-color;
  }

  .sd-file--single-image .sd-file__image-wrapper img {
    color: transparent;
  }

  .sd-input--disabled {
    opacity: 1;
  }

  .sd-input {
    color: $text-color;
  }

  .sd-dropdown__filter-string-input {
    color: $text-color;
  }

  .sd-item--disabled .sd-item__control-label {
    opacity: 1;
  }

  .sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
    fill: $text-color;
  }

  .sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
    background-color: $text-color;
  }

  .sv-components-column {
    overflow: auto;
  }

  .sv-components-row {
    border: 2px #{$survey-border} solid;
    border-radius: 4px;
    height: calc(100vh - 215px);
  }

  .sd-container-modern {
    margin-bottom: #{$container-padding};
  }

  .sd-question__required-text {
    color:  $survey-error-color;
  }

  .sv-components-row {
    border: 2px #{$survey-border} solid;
    border-radius: 4px;
    height: calc(100vh - #{$survey-container-offset});
  }
}
