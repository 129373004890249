#fr-plugin__dc-type {
  .MuiFormControl-root {
    &.dc-select {
      height: 46px;
      margin: 0px;
      border-radius: 2px;
      border: 1px solid rgba(34, 34, 34, 0.36);
      margin-bottom: 16px;
      .MuiInputLabel-formControl {
        top: -8px;
        left: 14px;
        background: #fff;
      }
      .MuiInputBase-root {
        &:before {
          border-bottom: 0px;
        }
        &.Mui-focused {
          .MuiNativeSelect-root {
            background-color: transparent;
          }
        }
      }
      label + .MuiInput-formControl {
        margin-top: 8px;
      }
      .Mui-focused {
        background: #fff;
        .MuiInputLabel-root {
          z-index: 9;
        }
      }
      .MuiNativeSelect-select {
        padding-left: 14px;
        option {
          padding-left: 14px;
          text-transform: capitalize;
        }
      }
      .MuiInput-underline {
        &.Mui-focused {
          &:after {
            border-bottom: 0px;
          }
        }
        &:before {
          width: 60%;
          left: 5%;
          bottom: 5px;
        }
      }
    }
  }
}

.dc-type-popup {
  display: flex;
  padding: 5px;
  div {
    padding: 5px 0px;
    margin: 0px 5px;
    cursor: pointer;
    svg {
      font-size: 22px;
    }
  }
}
