.file-drop-metadata-card {
  .metadata-tab-panel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    .file-folder-assignment {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 320px);
      overflow: auto;
    }
    .metadata-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 40px;
      margin-bottom: 0.75em;
    }
    .metadata-footer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 40px;
      padding-top: 5px;
    }
    .metadata-validity {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      .metadata-file-name {
        margin-bottom: 16px;
      }
      .validity-title {
        margin-bottom: 8px;
        height: 40px;
      }
      .validity-description {
        margin-top: -16px;
        margin-bottom: 16px;
      }
      .validity-range {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .MuiTypography-paragraph {
          margin-bottom: 8px;
        }
        .MuiFormControl-root {
          margin-right: 1em;
          max-width: 190px;
        }
        .datepicker-wrapper {
          display: flex;
          .datepicker-select {
            .MuiInputBase-adornedEnd {
              padding-right: 0;
            }
            .Mui-disabled {
              color: $icon-not-active-state;
            }
          }
        }
        .duration {
          display: inline-block;
          margin-right: 1em;
          .MuiFormControlLabel-root {
            max-width: 140px;
            padding-left: 1em;
          }
        }
        .open-date {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          margin-top: 40px;
        }
      }
      .select-wrapper {
        margin-top: 40px;
        .select-switcher {
          display: flex;
          align-items: center;
          .new-item {
            width: 2em;
            height: 2em;
            display: flex;
            span {
              padding: 0px;
            }
          }
        }
        .list-items-wrapper {
          margin-top: 15px;
          .tags-selector, .users-selector {
            min-height: 40%;
            border: 1px solid $border-color;
            .MuiListItemIcon-root {
              min-width: 32px;
            }
            .list-item {
              .remove-item {
                display: none;
                cursor: pointer;
              }
              &:hover {
                .remove-item {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .metadata-select {
      margin-top: 2em;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-direction: column;
      .select-switcher {
        display: flex;
        align-items: center;
        width: 10em;
        .new-item-button {
          display: flex;
          margin-left: 0.5em;
          .new-item-button-icon {
            &:hover {
              color: $light-ui-color;
            }
          }
        }
      }
      .list-items-wrapper {
        margin-top: 15px;
        display: flex;
        flex: 1;
        .tags-selector {
          .metadata-no-tags-selected {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .tags-selector, .users-selector {
          border: 1px solid $border-color;
          width: 100%;
          > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
          }
          .MuiListItemIcon-root {
            min-width: 32px;
          }
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
          .list-item {
            .remove-item {
              display: none;
              cursor: pointer;
            }
            &:hover {
              .remove-item {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .metadata-no-files-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .metadata-no-users-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.metadata-dialog {
  .metadata-dialog-paper {
    max-height: 80%;
    height: 100%;
    border: 0;
    overflow-y: scroll;
    .close-button {
      position: absolute;
      right: 0.5em;
      top: 0.8em;
    }
    .metadata-dialog-title {
      text-align: left;
      padding: 0.25em 1em;

      .MuiTypography-root{
        display: flex;
        flex-direction: row;
        align-items: center;
        .metadata-dialog-title-icon{
          color: $blue-icon;
        }
        .dialog-title-text{
          font-size: 1rem;
          flex-direction: column;
          .number-of-users-selected {
            font-size: 0.75rem;
          }
        }
      }
    }
    .metadata-users-dialog-content {
      overflow: hidden;
      margin-left: 1em;
      padding: 0;
      background: inherit;
      .metadata-dialog-search-bar {
        margin-right: 1em;
        position: relative;
        .search-bar-icon {
          position: absolute;
          left: 0;
          top: 0.2em;
        }
        .menu-button-right {
          position: absolute;
          right: 0;
        }
      }
      .metadata-users-list-wrapper {
        overflow-y: auto;
        height: 80%;
      }
    }
    .metadata-tags-dialog-content {
      padding: 0.5em 0;
      height: 100%;
      min-height:50%;
      overflow-y: auto;
    }
    .metadata-dialog-actions {
      min-height: 10%;
      justify-content: flex-end;
    }
  }
  .chip {
    width: auto;
    margin-right: 0.5em;
  }
  .active-filters {
    overflow-y: scroll;
    border-bottom: 1px solid var(--reader-border-color)
  }
  .filter-actions {
    padding: #{$base-padding * 2};
  }

  .clear{
    padding: #{$base-padding * 2};
  }
}
