.print-document-toast {
  margin-top: $base-padding;
  z-index: $zindex-toast;
  background-color: rgba($blue-color-all-themes, 0.85);
  border: $border-width $border-type $main-ui-color;
  .MuiSnackbarContent-root {
    background: $blue-color-all-themes;
    border-radius: 0px;
  }
  .print-document-inner {
    display: flex;
    justify-content: space-between;
    padding: $base-padding * 2;
    color: $reader-default-color-invert;
    .print-details {
      margin-left: $paragraph-margin;
      display: flex;
      flex-wrap: wrap;
      div {
        display: inline-block;
        width: 100%;
        h2 {
          font-size: 16px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
        p {
          font-size: 12px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }
    .close-print {
      font-size: 18px;
      align-self: center;
    }
    .print-button {
      font-size: 12px;
      &:hover {
        color: $reader-default-color-invert;
      }
    }
  }
}
