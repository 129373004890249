.history-log-workflow-tab {
  height: 100%;
  padding: 2em;
}

.history-log {
  overflow: auto;
  height: 100%;
  border: 1px solid $border-color;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .history-log-no-items {
    width: 100%;
    text-align: center;
  }
}
