.manage-submissions {
  --primary: #{$main-ui-color};
  height: calc(100% + #{$container-padding * 2});
  margin: -#{$container-padding};
  overflow-y: auto;
  justify-content: flex-start;
  min-height: 100%;
  width: calc(100% + #{$container-padding * 2});
  transition-property: all;
  transition-duration: 0.5s;
  padding: #{$base-padding * 2};
}

.manage-submissions-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1em;

  .manage-submissions-wrapper-content {
    display: flex;
    flex: 1 0 auto;
    padding: 0.5em;
    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
