.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      border-bottom: $default-border;
    }

    .MuiDialogContent-root {
      overflow-y: auto;
      background-color: $background-color-bright;
      min-height: 100px;      
    }

    .MuiDialogActions-root {
      border-top: $default-border;
      justify-content: space-between;
      padding: #{$base-padding * 2} #{$base-padding * 3};

      .spinner-container {
        display: block;
        text-align: right;

        .spinner {
          margin: 0;
        }
      }
    }
  }
  &.workflow-notifications-delete-dialog {
    .MuiDialogContent-root {
      min-height: auto;
    } 
  }
}

.language-dialog {
  .form-control-language {
    padding-top: 30px;
  }
}


.print-document-dialog {
  .form-control-print-document {
    padding-top: 30px;
    .MuiFormGroup-row {
      justify-content: space-between;
    }
  }
}
