.layout--printer {
  height: auto;

  @media print {
    .navbar {
      display: none;
    }
  }

  .reader {
    margin: 0;

    .tags {
      display: none;
    }
    .open-module {
      display: none;
    }
    .module {
      border-right: none !important;
    }
    .module-version-content:not(:last-child) {
      margin-bottom: $container-padding;
    }
  }

  .workflow-infos {
    margin: 0;

    &__group,
    & > li:not(:last-child) {
      margin-bottom: $paragraph-margin;
    }
  }
}
