.my-submissions-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1em;

  .my-submissions-wrapper-content {
    display: flex;
    flex: 1 0 auto;
    padding: 0.5em;
    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
