.toolbar {
  position: relative;
  z-index: $zindex-toolbar;
  flex-shrink: 0;

  .toolbar-content {
    flex-grow: 1;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .sort-button.active {
    background-color: rgba(92, 168, 243, 0.3);
  }
}
