@mixin module-title {
  position: relative;
  width: 100%;

  @include title;

  .favorite {
    position: absolute;
    top: 0;
    left: calc(($reader-side-padding / -2));
    transform: translateX(-50%);

    .MuiSvgIcon-root {
      vertical-align: text-top;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: min-content;
    margin-bottom: $title-margin;

    .numbering {
      display: flex;
      margin-right: 8px;

      &.hide {
        display: none;
      }
    }

    span {
      word-break: break-word;
    }
  }

  .open-module {
    position: absolute;
    top: 0;
    right: calc($reader-side-padding / -2);
    transform: translateX(-50%);
  }
}
