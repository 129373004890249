.dashboard-card {
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .MuiPaper-root {
    display: flex;
    flex-direction: column;

    .card-header {
      flex-shrink: 0;
      flex-wrap: nowrap;
      p {
        font-size: 0.875rem;
        padding-left: 0.1rem;
        @include media-breakpoint-down('xs') {
          font-size: 0.75rem;
        }
      }
      .icon--large {
        @include media-breakpoint-down('xs') {
          margin-top: 6px;
          width: 30px;
          height: auto
        }
      }
      .MuiGrid-item{
        @include media-breakpoint-down('xs') {
          padding: 6px;
        }
      }
      .tab-counter {
        padding: 0.1em 0.2em;
        border-radius: 0.15em;
        background-color: $counter-background;
        margin-left: 0.2em;
        margin-right: 1.7em;
        color: $black-color-all-themes;
        &.active {
          background-color: $counter-background-active;
        }
        @include media-breakpoint-down('xs') {
          margin-right: 1em;
          padding: 0;
        }
      }
    }

    .tabs {
      height: calc(100% - #{$dashboard-card-header-height});
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;

      .tabs-header {
        border-top: none;
      }

      &.tabs-hidden {
        .tabs-header {
          display: none;
        }
      }
    }
    .MuiTabs-centered {
      justify-content: flex-start;
      button {
        max-width: 33.333%;
      }
    }
  }

  .full-width-card {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    .tab {
      min-width: 95px;

      span[class^='MuiTab-labelContainer'] {
        padding: 6px 12px;
      }
    }
  }

  .card-header {
    padding: $card-padding;
    max-height: $dashboard-card-header-height;
    height: $dashboard-card-header-height;

    .card-header-trail {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      right: 4px;
    }
  }

  .MuiTabs-indicator {
    bottom: 0;
  }


  .unread-item {
      .list-item-primary {
        font-weight: bold;
      }
  }


  .card-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    .item__task {
      &--unread {
        .list-item-primary {
          font-weight: bold;
        }
      }

      &--state {
        &-blue {
          .list-item-meta {
            color: cornflowerblue;
          }
        }

        &-orange {
          .list-item-meta {
            color: orange;
          }
        }

        &-red {
          .list-item-meta {
            color: red;
          }
        }
      }
    }

    div[class^='MuiListItemText-root'] {
      padding: 0;
    }

    ul {
      // [class^='MuiList-root'] {
      padding: 0;
    }
  }
}
